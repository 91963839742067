.payment_title_header {
     display: flex !important;
     justify-content: space-between;
     align-items: center;
     width: 100%;
     padding: 1rem 1rem; 
     border-bottom: 1px solid var(--alpha-40);
}


.btn_add_new {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2rem !important;
    width: 180px;
    padding: 0 5px;
    height: 40px;
    background: linear-gradient(0deg, rgba(128, 128, 128, 0.25) -17935.87%, rgba(128, 128, 128, 0.12) -15265.57%, rgba(128, 128, 128, 0.1) -12990.87%);
    border: 1px solid var(--alpha-40);
    border-radius: 5px;
    cursor: pointer;
}

.payment-img {
    width: 30px;
    height: 30px;
}

.payment_modal {
    height: 100%;
}

.payment_add_method {
     width: 700px;
     position: fixed;
     top: 50%;
     left: 50%;
     transform: translate(-50%,-50%);
     z-index: 300;
}

.payment-image-upload {
    display: none;
}

.upload-file-img {
    margin: auto;
    background: transparent;
    font-family: Circular Std;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 20px;
    color: #9A9696;
    height: 60px;
    width: 230px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border: 1px dashed #9A9696;
    box-sizing: border-box;
    border-radius: 15px;

}
.upload-file-img:hover {
    cursor: pointer;
}

.add_payment_form {
    width: 100%;
    height: auto;
    margin: 1.5rem auto;
    padding: 1rem;
}

.payment_input {
    width: 100%;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid var(--alpha-40) !important;
    outline: none !important;
}

.payment_input:focus {
   outline: none !important;
   border-bottom-color: var(--alpha-primary) !important;
}



.payment_label {   
    z-index: 100;
    font-family: Circular Std;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    padding: 0px .5rem;
    /* identical to box height, or 143% */
     
    letter-spacing: 1px;
    color: var(--alpha-80);
}

.image-preview {
    display: flex;
    width: 100%;
    /* margin-left: 5.5rem; */
    align-items: center;
    margin-top: .5rem;
}

.image-preview img {
    width: 26px;
    height: 26px;
}

.image-preview div {
    font-family: Circular Std;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #9A9696;
    margin-left: 1rem;
}
