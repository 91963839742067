.card_wrapper_eta {
    height: fit-content !important;
}
.eta-card-body {
    height: auto !important;
    position: relative;
} 
.eta-container{
    height: 100% !important;
} 
.permission-n-cancellation {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
   
}


.permission-n-cancellation div {
    width: 394px;
    height: 80px;
    display: flex;
    align-items: center;
   justify-content: space-between;
    padding: 0rem 0.75rem;
    background: rgba(226, 226, 226, 0.4);
    border-radius: 15px;
    cursor: pointer;
    
}
.permission-n-cancellation div p {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 1.25px;
    color: #333333;
    margin-top: 1rem;
}

.permission-n-cancellation div span {
    color: rgba(51, 51, 51, 0.4);
}

.back-navigation-set {
    margin-right: 0.5rem;
    cursor: pointer;
}
.paginate_setting {
    display: flex;
    width: 100%;
    justify-content: center;
}
.set-priv-container {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    gap: 1rem;
    
}
.set-priv-container div.privacy {
    display: flex;
    border-bottom: 1px solid rgba(226, 226, 226, 0.4);;
    padding: .5rem 0 1rem 0;
    margin: .5rem 0;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.eta-form {
    width: 100%;
    height: auto !important;
    padding: 2rem 0;
    position: relative !important;
}

.eta-form form {
     width: 100%; 
   
}
.eta-form .eta-list {
    display: flex;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem 2rem 1rem;
    gap: 1rem;
}
.eta-form .eta-list:nth-child(n+2){
    padding-top: 2rem;
}
.eta-form form .eta-input {
    position: relative;
    width: 29%;
    display: flex;
    flex-direction: column;
}
.eta-form form .inputs {
     width: 100%;
     position: relative;
     gap: 1rem;
     display: flex;
     align-items: center;
     height: auto;
     
}
.from-state::-webkit-scrollbar {
    width: 0.5em;
  }
   
  .from-state::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }
   
  .from-state::-webkit-scrollbar-thumb {
    background-color: var(--alpha-green);
    outline: 1px solid var(--alpha-blue-back);
    border-radius: 10px;
    transition: all 0.3s ease-in;
  }
  .from-state::-webkit-scrollbar-thumb:hover {
      background-color: var(--alpha-blue-back);
  }
.eta-form form .inputs input {
    width: 97%;
    position: absolute;
    height: 60px;
    border: 1px solid rgba(51, 51, 51, 0.8);
    border-radius: 10px;
    padding: 0 0.5rem;

}
.eta-form form .inputs input:focus {
    outline: var(--alpha-green);
    border: 2px solid var(--alpha-green);
}
.eta-form form .inputs .toggler-up {
    color: var(--alpha-green) !important;
}
.eta-form form .from-state {
    position: absolute;
    border: 1px solid var(--alpha-green);
    background: var(--text-white);
    width: 97%;
    max-height: 300px;
    margin-top: 15%;
    z-index: 100000;
    left: 0;
    border-radius: 10px;
    padding: 0.5rem 0.5rem 1rem 0.5rem;
    box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.3);
    transition: all 0.3s  ease-in-out;
    overflow-y: auto;
}


.eta-form form .from-state  span {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--text-gray-600);
    padding: 0.25rem;
    color: var(--alpha-40);
   
}
.eta-form form .from-state  span:hover {
    cursor: pointer;
    color: var(--alpha-blue-back);
} 
.eta-form form .from-state  span:nth-child(n+2){
    margin-top: 0.5rem;
}
.eta-form form .inputs .input-toggler {
     position: absolute;
     z-index: 1000;
     right: 5%;
     top: 50%;
     transition: all 0.3s ease-in-out;
     transform: translateY(-50%);
}
.eta-form form .eta-actions {
    width: 13%;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    
}
.eta-form form .eta-actions .save-icon, .eta-form form .eta-actions .trash-can {
     display: flex;
     align-items: center;
     gap: 0.25rem;
     cursor: pointer;
}
.eta-form form .eta-actions .save-icon {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--alpha-80)
}
 .eta-form form .eta-actions .trash-can {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;    
    text-align: center;
    color: #ED3237;
 }


.edit-eta-forms {
     width: 100%;
     display: flex;
     align-items: center;
     justify-content: space-between;
     padding: 0.5rem 0;
}
.edit-eta-forms .eta-actions {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.edit-eta-forms .edit-select, .edit-eta-forms .edit-input {
    width: 28%;
    border-radius: 10px;
    height: 60px;
    border: 1px solid var(--alpha-40);
}
.edit-eta-forms .eta-actions .trash-can {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 500;
    font-size: 14px; 
    text-align: center;
    color: #ED3237;
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 2px;
}
.edit-eta-forms .eta-actions .save-icon {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: var(--alpha-80);
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 2px;
}
.edit-eta-forms .eta-actions .save-icon svg.icon-update {
    font-family: "Circular Std";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: var(--alpha-80);
}
select.edit-select:focus, .edit-input:focus {
    border: 2px solid var(--alpha-green) !important;
    padding: 0 5px;
}
.edit-eta-forms .edit-input:focus {
     outline: none !important;
}

    select.edit-select option:checked {
        background: rgb(0,0,0);
        background-color: rgb(0,0,0) !important;
        color: rgb(255,255,255) !important;
    }
.bg-edit-eta {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: 999999;
    width: 100%;
    background: rgba(0,0,0,0.5);
}
.bg-edit-eta .edit-modal {
      position: fixed;
      top: 0;
      left: 50%;
      transform: translate(-50%,-50%);
      transition: all 0.3s  ease-in-out;
      width: 80%;
      height: 300px;
      border-radius: 10px;
      background: #fff;
      display: none;
}
.bg-edit-eta .edit-modal .close-edit-modal {
    position: fixed;
    top: 1%;
    right: 1%;
    cursor: pointer;
    
}
.bg-edit-eta .edit-modal.isopen {
    top: 50%;
    transition: all 0.3s  ease-in-out;
    display: block;
}
.bg-edit-eta .edit-modal .close-edit-modal:hover {
    color: var(--alpha-red);
}
.saved-check {
    color: var(--alpha-green);
}
.edit-eta-form-modal {
    top: 50%;
    left: 67%;
    width: 100%;
    height: 100%;
    transform: translate(-64%,-50%);
}
.upload-criteria {
    color: var(--alpha-80);
}


.alpha-checklist {
      position: relative;
      width: 100%;
      height: 100%;
}
.alpha-checklist .payment_modal {
    z-index: 999999999;
    position: fixed !important;
}
.alpha-checklist .alpha-overflow {
      position: fixed;
      width: 100vw;
      height: 100%;
      min-height: 100vh;
      min-width: 100vw;
      top: 0;
      left: 0%;
      bottom: 0;
      z-index: 999999;
      background: rgba(0, 0, 0, 0.75);
      cursor: pointer;
}


.criteria-header .criteria-actions {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.transactions-item .criteria-actions-btn {
    display: flex;
    justify-content: center;
    /* gap: 1.5rem; */
}
.transactions-item .criteria-actions-btn div:nth-child(1){
    margin-left: -10px;
    color: var(--alpha-40);
    cursor: pointer;
}
.transactions-item .criteria-actions-btn div:nth-child(2){
    margin-left: 20px;
    color: var(--alpha-red);
    cursor: pointer;
}