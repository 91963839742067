.ant-switch-checked {
    background-color: var(--success) !important;
}
.toggle-class {
    font-family: Circular Std;
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 15px;
    letter-spacing: -0.02em;
    color: #1A1A1A;
    cursor: pointer;
}
.deactivate {
    color: var(--alpha-40);
}

.sub-grid-item {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(4, minmax(200px, 1fr));
    text-align: center;
  
}

.product-header {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(150px, 1fr));
    border-bottom: 1px solid var(--alpha-40);
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    align-items: center;
    color: var(--alpha-80);
    padding: 1rem 0;

}

.product-listing {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(150px, 1fr));
    padding: 1rem 0;
    overflow-x: hidden;

}

.sub-category-header {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(4, minmax(200px, 1fr));
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    font-style: normal;
    color: var(--alpha-red-800);
    text-align: center;
}

.btn_add_category, .back__arrow {
    cursor: pointer;
}
.add_product_label {
    font-family: Circular Std;
    font-style: normal;
    font-weight: 900;
    width: 100%;
    font-size: 14px;
    line-height: 20px;    
    color: var(--alpha-80);
    
}
.back__arrow {
    font-size: 14px;
}
.product_image {
    width: 77px;
    height: 77px;
    border: 1px dashed #9A9696;
    box-sizing: border-box;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #9A9696;
}
.banner_image {
      display: flex;
      width: 324px;
      height: 60px;
      left: 836px;
      border: 1px dashed #9A9696;
      box-sizing: border-box;
      border-radius: 15px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: #9A9696;
}
.checkboxes {
    width: 100%;
    display: flex;
    align-items: center;
    font-family: Circular Std;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.0125em;
    color: var(--alpha-80);
}
.checkboxes div {
    display: flex;
    align-items: center;
    font-family: Circular Std;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.0125em;
    color: var(--alpha-80);
}
.checkboxes div:nth-child(n+2){
    margin-left: 20px;
} 
.category_name {
    width: 100%;
}
.category_name input {
    width: 80%;
    border: 0;
    border-bottom: 1px solid var(--alpha-40);
    outline: none;
}
.category_name textarea {
    width: 80%;
    border: 0;
    border-bottom: 1px solid var(--alpha-40);
    outline: none; 
}
.category_name select{
    width: 80%;
    border: 0;
    margin-top: 1rem;
    border-bottom: 1px solid var(--alpha-40);
    outline: none; 
}
.category_name input:focus, .category_name textarea:focus, .category_name select:focus {
    outline: none;
} 

.sizes_input {
    width: 17% !important;
}


.add_more_size {
    color: var(--alpha-80);
    font-family: Circular Std;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.0125em;
    cursor: pointer;
}

.btn-save, .cancel-btn {

    text-transform: uppercase;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
}
.btn-save {
    padding: .5rem 2.5rem;
    background: #1A1A1A;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    color: var(--text-white);
    height: 50px;
    width: 200px
}
.cancel-btn  {
    background: transparent;
    border: none;
    margin-left: 2.5rem;
    height: 50px;
}
/* .data-heading-padding{
    margin-bottom: 5rem;
} */

.sub-category-listHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
}
.subcategory-content {
    padding: .5rem auto;
}

.sub-category-listHeader .subheader-title {   
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 1.25px;
    color: var(--text-dark);
}

.sub-category-listHeader .subheader-btn {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.25px;
    color: var(--alpha-80);
  
}

.subcategory-listing {
    list-style: none;
}

.sub-category-listHeader .subheader-btn button {
    padding: .5rem;
    background: linear-gradient(0deg, rgba(128, 128, 128, 0.25) -17935.87%, rgba(128, 128, 128, 0.12) -15265.57%, rgba(128, 128, 128, 0.1) -12990.87%);
    border: 1px solid var(--alpha-40);
    box-sizing: border-box;
    border-radius: 5px;
}

.list-sub-categories {
    width: 40%;
    display: grid;
    grid-template-columns: repeat(3, minmax(100px, 1fr));
    margin-top: 20px;
    font-family: Circular Std;
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: -0.02em;
    color: #1A1A1A;
}

.subcategory-listing hr {
    width: 40%;
    color: var(--alpha-40);
}

.subcategory-listing .sub-edit {
    color: var(--alpha-40);
    cursor: pointer;
}

.modal_for_type {
    height: 300px !important;
}

.type_modal {
    padding-bottom: 3rem;
    width: 60%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 300;
}

.type_header {
    color: var(--alpha-black-500) !important;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
}
.add_type_form {
    width: 100%;
    height: auto;
    margin: 1.5rem auto;
    padding: 1rem;
}

.type_input {
    width: 100%;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid var(--alpha-40) !important;
    outline: none !important;
}

.type_input:focus {
   outline: none !important;
   border-bottom-color: var(--alpha-primary);
}



.type_label {   
    z-index: 100;
    font-family: Circular Std;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    padding: 0px .5rem;
    /* identical to box height, or 143% */
     
    letter-spacing: 1px;
    color: var(--alpha-80);
}
.cta_btn {
    display: flex;
   justify-content: center;
   align-items: center;
   margin-top: 4rem;
}
.type_button {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #FFFFFF;
    width: 230px;
    height: 48px;
    background: #1A1A1A;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

.type_cancel_button {
    width: 100px;
    height: 13px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #1A1A1A;
    border: none !important;
    background: transparent !important;
    margin-left: 1rem;
    cursor: pointer;
}
.gender_btn_add {
    cursor: pointer;
}
.remove-bullet-points {
    list-style: none;
}

#image_url, #image_url2 {
    width: 100%;
    background: #FFFFFF;
    border: none;
    outline: none;
    cursor: pointer;
    display: none;
}

.type-tooltip {
    display: flex;
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    align-items: center;
    justify-content: flex-end;
    color: var(--alpha-40);
    margin-top: 5px;
    cursor: pointer;
}
.type-tooltip span {
    margin-left: 5px;
}

.data_heading_product {
    padding: 1rem 1rem; 
    border-bottom: 1px solid var(--alpha-40);
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.ctas_button_product {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ctas_button_product .btn_add_category {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2rem !important;
    width: 260px;
    padding: 0 5px;
    height: 40px;
    background: linear-gradient(0deg, rgba(128, 128, 128, 0.25) -17935.87%, rgba(128, 128, 128, 0.12) -15265.57%, rgba(128, 128, 128, 0.1) -12990.87%);
    border: 1px solid var(--alpha-40);
    border-radius: 5px;
    
}
@media screen and (min-width: 48em) {
    .product-header {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(6, minmax(150px, 1fr));
        font-style: normal; 
        font-weight: 900;
        font-size: 14px;
        align-items: center;
        color: var(--alpha-80);
        width: 100%;
        padding: 1rem 0;
    
    
    }
    
    .product-listing {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(6, minmax(150px, 1fr));
        padding: 1rem 0;
    } 

    #image_url, #image_url2 {
        width: 80%;
    }
}

