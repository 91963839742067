.card_size {
    height: 130px;
}
.card_size_total {
    height: 285px
}
.card_size_total p {
    font-family: Circular Std;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 0.7px;
    color: var(--alpha-80);
}

.customers_container ul {
    display: flex;
    justify-content: space-between;
    padding: 1rem 10rem;
    list-style: none;
    background: var(--text-white);
    border-radius: 10px;
}

.card__wrapper {
    padding-bottom: 1rem;
}



.card__wrap .table {
    padding: auto 2rem;
}

.customers_container ul > li  {
    color: var(--alpha-40);
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 1.5px;
    padding: .5rem 1.5rem;
    cursor: pointer !important;
}
.tab_color {
    color: var(--alpha-40);
}
.tabs_active {
    background: var(--alpha-red-35);
    color: var(--alpha-red) !important;
    border-radius: 15px;
    transition: all .3s ease-in;
}
.card__header {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    padding: .7rem 0;
    letter-spacing: 1.25px;
    text-align: center !important;
    color: var(--alpha-black-500);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background: var(--text-white);
    border-bottom: 1px solid var(--alpha-40);
}

.sort {
    background: linear-gradient(0deg, rgba(128, 128, 128, 0.25) -17935.87%, rgba(128, 128, 128, 0.12) -15265.57%, rgba(128, 128, 128, 0.1) -12990.87%);
    border: 1px solid var(--alpha-40);
    box-sizing: border-box;
    border-radius: 5px;
    width: 95.75px;
    height: 25px;
    padding: auto .5rem;
    cursor: pointer;
}

.button_sort {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: center;

}

.header_order_title {
    width: 100%;
}

.icon_one {
    margin-right: 1rem;
}

.sortable_dropdown {
   position: absolute;
   right: 1rem;
   text-align: left;
   line-height: 1.5;
   padding: 1rem;
   z-index: 100;
   width: 158.59px;
   height: 74px;
   background: var(--text-white);
   box-shadow: 0px 0px 4px #00000040;
   border-radius: 5px;
   cursor: pointer;
   display: none;

}

.activate_sort {
    display: block;
}


.divider {
     width: 100%;
     height: 3px;
     border-bottom: 1px solid #808080;
   
}

.header_order_title {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 1.25px;
    color: var(--alpha-black-500);
}

.item_suspend button, .item_suspended button {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    width: 103px;
    height: 25px;
    background: linear-gradient(0deg, rgba(128, 128, 128, 0.25) -17935.87%, rgba(128, 128, 128, 0.12) -15265.57%, rgba(128, 128, 128, 0.1) -12990.87%);
    width: 103px;
    height: 25px;
    border: 1px solid var(--alpha-40);
    border-radius: 5px;
}

.item_deactivate button {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    width: 103px;
    height: 25px;
    background: var(--text-white);
    border: 1px solid var(--alpha-40);
    border-radius: 5px;
}

.item_deactivated button {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    width: 103px;
    height: 25px;
    background: var(--text-white);
    border: 1px solid var(--alpha-40);
    border-radius: 5px;
    color:  var(--alpha-40);
}


.table-header {
    width: 100%;
    margin-left: .5rem;
    
}

/* 
.header__status {
      margin-left: 5.6rem;
}
.header__userId {
    margin-left: 2.3rem;
}

.header_phone {
    margin-left: 2.3rem;
}
.header_email {
    margin-left: 3.6rem;
}

.header___status {
    margin-left: 5.5rem;
}

.header___userId {
    margin-left: 3.5rem;
}
.header___phone {
    margin-left: 2.3rem;
}

.header___email {
    margin-left: 4.5rem;
} */

.header__orders {
    margin-left: 5.3rem;
}

.header_last_transaction {
    margin-left: 3rem;
}
.item_suspend_active .item_name, a.item_name_link {
    font-family: Circular Std;
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.02em;
    cursor: pointer;
    display: flex;
    align-items: center;

}
.item_suspend_active .item_name a, a.item_name_link {
    color: var(--alpha-blue-back);
    text-decoration-line: underline;
}
.item_suspend_inactive .item_name span{
    font-family: Circular Std;
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.02em;
    text-decoration-line: underline;
    
    /* Alpha alternative */
    
    color: #849614;
    
    opacity: 0.3;
}

.item_suspend_inactive .item_status, .item_suspend_inactive .item_userId, .item_suspend_inactive .item_phone, .item_suspend_inactive .item_email {
    color: #333333;
    opacity: 0.3;
}
.item_name .store-logo {
    border-radius: 50%;
}
.item__name {
    cursor: pointer;
}
.item__active {   
    color: var(--success);
}
.item__inactive {
    color: var(--alpha-red)
}

.store-owner-header {
    margin-left: 1.5rem;
}

.store-owner-page {
      display: flex !important;
      justify-content: space-between;
      align-items: center;
}
.body-of-card {
    border-radius: 15px !important;
}
.card-prod-upload {
    background: #192150;
    height: 150px;
}

.card-prod-upload h6 {
    color: var(--alpha-lemon);
}

.card-prod-sold {
    background: var(--alpha-80);
    height: 150px;
}
.card-prod-sold h6 {
    color: var(--alpha-green);
}
.card-store-visit {
    background: var(--alpha-black-500);
    height: 150px;
}

.card-store-visit h6 {
    color: var(--alpha-lemon);
}
.body-of-card h6{
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 1.25px;
}

.body-of-card p {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1.25px;
    color: var(--text-white);
}

.store-img-name {
     width: 100%;
     height: 100%;
     display: flex;
}
.image-div {
    background: var(--alpha-black-20);
    width: 50px;
    height: 50px;
    text-align: center;
    padding: 3px;
    border-radius: 50%;
}
.store-img-name  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.store-name-div {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
}
.store-name-div span:first-child {
    font-family: Circular Std;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.75px;
    color: var(--alpha-80);
}

.store-name-div span:last-child {
    font-family: Circular Std;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.75px;
    color: #192150;

}
.buyer-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.buyer-det {
    width: 262px;
    height: 143px;

}
.buyer-data .card {
    background: var(--alpha-blue-back);
    border-radius: 15px;
    width: 100%;
    height: 100%;
}
.customer-total-purchase h6 {
    color: var(--alpha-lemon) !important;
}

.order-processing {
    color: var(--alpha-40) !important;
}

.order-intransit {
    color: var(--alpha-80) !important;
}
.order-declined {
    color: var(--alpha-red) !important;
}
.order-delivered {
    color: var(--success) !important;
}

.table_text td{
     font-size: 10px;
     width: 100%

}

.table_text .item_name {
     width: 180px;
}