 .btn__add_new-coupon {
    width: 230px;
    height: 48px;
    border-radius: 5px;
    background: #1A1A1A;
    color: var(--text-white);
    border: 1px solid #1A1A1A;
}
.data_heading .code {
     font-family: "Circular Std", sans-serif !important;
     font-weight: 900 !important;
     font-size: 14px !important;
     line-height: 20px !important;
}

.dialog-box {
      width: 800px;
      height: 450px;
      max-width: 892px;
      max-height: 480px;
      border-radius: 15px !important;
      background: var(--text-white);
      border: 1px solid var(--text-white) !important;
} 
.coupon-box {
    width: 400px;
    height: 200px;
    border-radius: 15px !important;
    background: var(--text-white);
    border: 1px solid var(--text-white) !important;
    outline: none !important;
}
.coupon-box .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding-top: 1rem; */
}
.coupon-box .content .action-buttons{
    display: flex;
    gap: 1rem;
}
.coupon-box .content .action-buttons button {
    padding: 0.5rem 0;
    width: 80px;
    border: none;
    background: transparent;
    outline: none;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 14px;
}
.coupon-box .content .action-buttons button:first-child {
   background: var(--alpha-blue-back);
   color: var(--text-white);
   border-radius: 8px;
}
.coupon-box .content .action-buttons button:last-child {
    background: var(--alpha-40);
    color: var(--alpha-blue-back);
    border-radius: 8px;
}

.dialog-box .box-hr, .coupon-box .box-hr {
      border: 1px solid var(--alpha-40) !important;
}

.dialog-box .coupon-header-add, .coupon-box .coupon-header-add {
     font-family: "Inter", sans-serif;
     font-weight: 700;
     font-size: 16px;
     line-height: 16px;
     letter-spacing: 1.25px;
}

.dialog-box .dialog__content_box {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 3rem;
      padding: 2rem;
}

.dialog-box .dialog__content_box .discount_name-amount{
      display: flex;
      justify-content: space-between;
      gap: 3rem;
      position: relative;
}
.dialog-box .dialog__content_box .discount_name-amount .discount{
    display: flex;
    flex-direction: column;
    width:100% !important;
} 

.dialog-box .dialog__content_box .discount_name-amount input {
      border: none;
      outline: none;
      border-bottom: 1.5px solid var(--alpha-40);
}
.dialog-box .dialog__content_box .discount_name-amount input:focus {
    border-bottom: 1.5px solid var(--alpha-success);
}

.dialog-box .dialog__content_box .discount_name-amount label, .discount-type label, .end-date label {
    font-family: "Circular Std", sans-serif;
    font-weight: 900;
    font-size: 14px;
    line-height: 20px;
    color: var(--alpha-80);
}

.dialog-box .dialog__content_box .date__discount {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    width: 700px;
}

.dialog-box .dialog__content_box .date__discount .start-date, .dialog-box .dialog__content_box .date__discount .end-date{
      width: 180px !important;
      display: flex;
      flex-direction: column;
      position: relative;
}

.dialog-box .dialog__content_box .date__discount .discount-type {
    width: 220px !important;
    display: flex;
    flex-direction: column;
    position: relative;
}

.dialog-box .dialog__content_box .date__discount .discount-type select {
    /* -webkit-appearance: none;
   -moz-appearance: none; */
   cursor: pointer;
   width: 100% !important;
   padding: 0.25rem;
}
.dialog-box .dialog__content_box .date__discount input, .dialog-box .dialog__content_box .date__discount select {
    height: 60px !important;
    border: 1px solid var(--alpha-40);
    border-radius: 10px;
    
}
.dialog-box .dialog__content_box .react-datepicker__input-container  input {
    padding: 0.25rem ;
    cursor: pointer;
}
.dialog-box .dialog__content_box .date__discount .end-date .calender-style {
    position: relative;
}
.dialog-box .dialog__content_box .date__discount .end-date .chev-down {
    position: absolute;
    right: -2rem;
    top: 50%;
    transform: translateY(-50%);
}
.add__new_coupon-header {
    position: relative;
}
.add__new_coupon-header .cancel-op {
    position: absolute;
    right: 1rem;
    top: -5px;
    cursor: pointer;
    color: var(--alpha-80);
}
.add__new_coupon-header .cancel-op:hover {
      color: var(--alpha-red);
}
.success-n-failed {
     margin-top: -1rem;
}
.table-responsive .cursor-pointer {
    cursor: pointer;
}