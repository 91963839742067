@font-face {
  font-family: 'Circular Std';
  src: local('Circular Std'), url("./fonts/circular_std_book_3.ttf") format('truetype');
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@1,200&display=swap');

:root {
  --alpha-black: #1A1A1A;
  --alpha-red: #ED3237;
  --alpha-red-50: rgba(237,50,55,0.5);
  --alpha-red-800: #EB0000;
  --alpha-red-35: rgb(237,50,55, 0.35);
  --text-white: #ffffff;
  --text-gray-600: #aaaaaa;
  --text-gray-300: rgba(255, 255, 255, 0.2);
  --alpha-80: rgba(51, 51, 51, 0.8);
  --alpha-40: rgba(51, 51, 51, 0.4);
  --success: #00B412;
  --alpha-success : #009A59;
  --alpha-black-500: #333333;
  --body-bg: #e6e9ed;
  --alpha-gray-400 : #BDBDBD;
  --info: #FFCC4D;
  --alpha-info-900: #FF9900;
  --alpha-10:rgba(51, 51, 51, 0.1);
  --alpha-black-20: rgba(0, 0, 0, 0.2);
  --alpha-lemon: #C2D068;
  --alpha-primary: #0096EB;
  --alpha-violet: #76076B;
  --alpha-blue-back: #192150;
  --alpha-green:  #72FFC9;
}

body {
  margin: 0;
  font-family: Inter, "sans-serif";
  padding: 0;
  background:  var(--body-bg) !important;
}

body::-webkit-scrollbar {
  width: 0.5em;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
 
body::-webkit-scrollbar-thumb {
  background-color: var(--alpha-green);
  outline: 1px solid var(--alpha-blue-back);
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}
body::-webkit-scrollbar-thumb:hover {
     background-color: var(--alpha-blue-back);
} 
.m-height {
     min-height: 162px !important;
  
}
.m-height .card-body {
    height: 100% !important;
    display: flex !important;
    flex-direction: column;
    align-items: center !important;
    justify-content: center !important;
}
.menu_items {
     position: relative;
}

.bg__overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2) !important;
  z-index: 100;
  cursor: pointer;
}

.bg_notification__overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2) !important;
  z-index: 100;
  cursor: pointer;
}
.bg__overlay_type {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2) !important;
  z-index: 100;
  cursor: pointer;
}
.bg_overlay_transaction{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2) !important;
    z-index: 100;
    cursor: pointer;
}
.bg__overlay_size {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2) !important;
  z-index: 100;
  cursor: pointer;
}
.bg__overlay_gender {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2) !important;
  z-index: 100;
  cursor: pointer;
}
.ant-layout-sider.ant-layout-sider-dark{
    width: 150px !important;
    height: auto !important;
    max-width: 200px !important;
    min-width: 100px !important;
    background: var(--alpha-black) !important;
    z-index: 8;
}



.ant-menu-dark {
  background: var(--alpha-black) !important;
  margin-top: 4rem !important;
  height: 100%;

}

.ant-menu-item, .ant-menu-item-selected {
    font-family: Inter;
    font-weight: 700;
    font-style: normal;
    font-size: 13.5px;
    line-height: 16px;
    color: var(--text-white) !important;
    background: var(--alpha-black) !important;
}
.active-class {
  background:  var(--text-gray-300) !important;
  border-left: 4px solid var(--alpha-red);
}

.ant-layout-header.site-layout-sub-header-background {
  background: var(--alpha-black) !important;
  z-index: 8;
}
.main_form {
    width: 50%;
    margin-left: 7rem;
}

.search__input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto 2rem;
}

.has-search .form-control {
  padding-left: 2.375rem;
  border-radius: 25px;
  width: 100%;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: var(--text-gray-600)
 
}
.user_note .icon {
    color: var(--text-white) !important;
}
.user_note .icon:nth-child(n+2){
  margin-left: 1rem;
}
.content_page {
  height: 100%;
  /* overflow-x: hidden; */
}
.dropdown {
     position: absolute;
     right: 2rem;
     top: 4rem;
     width: 150px;
     height: auto;
     background: var(--text-white);
     border-bottom-left-radius: 12px;
     border-bottom-right-radius: 12px;
     padding: 1rem 0;
     box-shadow: 2px 2px 10px var(--text-gray-600);
     display: none !important;
     transition: all .3s ease-in-out;
     z-index: 100;
}
.dropdown-items {
     width: 100%;
}
.admin__profile {
      width: 100%;
      margin-left: 2rem;
      font-style: 16px;
      font-weight: 700;
      font-family: Inter;
      
 
}
.admin__profile a{
    color: var(--alpha-black);
    text-transform: uppercase;
}
.open_dropdown {
    display: block !important;
}
.admin_profile_icon {
   font-weight: 900;
   font-size: 18px;
   color: var(--success);
   margin-right: .5rem;
}
.admin__logout {
    color: var(--alpha-blue-back);
     margin-left: 2rem;
     font-size: 16px;
     font-family: Inter;
     font-weight: 500;
     text-transform: uppercase;
     cursor: pointer;
     letter-spacing: 3pxS;
}
.admin__logout:hover {
     opacity: .6;
}
.admin_logout_icon {
  font-weight: 900;
  font-size: 18px;
  color: var(--alpha-red);
  margin-right: .5rem;
}

.ant-layout {
    background: #e6e9ed !important;
    height: auto;
}
.ant-layout-content {
  height: 100% !important;
}

.card {
  background: #FFFFFF;
box-shadow: 0px 0px 10px 2px rgba(255, 255, 255, 0.05);
  border-radius: 15px;
}
.otp-field {
    width: 40px;
    border-bottom: 1px solid var(--alpha-blue-back) !important;
    text-align: center !important;
    border: none;
    outline: none;
}

.form-group-otp {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.error_side {
    position: absolute;
    top: 50%;
    left: 30%;
    padding: .5rem 1rem;
    border: 1px solid #CA3433;
    text-align: center;
    background:  #CA3433;
    border-radius: 5px;
    color: #8B0000 !important;
}


.view_admins_error {
  padding: .5rem 1rem;
  border: 1px solid #CA3433;
  text-align: center;
  background:  #CA3433;
  border-radius: 5px;
  color: #8B0000 !important;
}
.otp-resend {
    cursor: pointer;
}

.otp_success {
  position: absolute;
  top: 50%;
  left: 30%;
  padding: .5rem 1rem;
  border: 1px solid var(--success);
  text-align: center;
  background:  var(--success);
  border-radius: 5px;
  color: rgb(2, 26, 2) !important;
}

.left-chevron, .middle-chevron ,.right-chevron {
     height: 35px;
     width: 35px;
     background: var(--text-white);
     border: 1px solid var(--alpha-40);
     outline: none;
     cursor: pointer;
}
.left-chevron {
  border: 1px solid var(--alpha-40);
  box-sizing: border-box;
  border-radius: 5px 0px 0px 5px;
}

.middle-chevron {
  width: 45px !important;
  background: var(--body-bg) !important;
 
}

.right-chevron {
  box-sizing: border-box;
  border-radius: 0px 5px 5px 0px;
}

.earning-link {
  letter-spacing: -0.02em;
  text-decoration-line: underline;
  color: var(--alpha-blue-back);
  font-family: "Circular Std";
  cursor: pointer;
}
.earning-link a {
   color: var(--alpha-blue-back);
}
.inventory-btn {
    background: linear-gradient(0deg, rgba(128, 128, 128, 0.25) -17935.87%, rgba(128, 128, 128, 0.12) -15265.57%, rgba(128, 128, 128, 0.1) -12990.87%);
    border: 1px solid var(--alpha-40);
    box-sizing: border-box;
    border-radius: 5px;
    width: 100%;
    position: relative;
    width: 210px;
    height: 48px;  
    top: -3rem;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.25px;
    color: var(--alpha-80);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
}
.out-of-stock {
  color: var(--alpha-red) !important;
}
.trash-icon {
      color: var(--alpha-red) !important;
      cursor: pointer;
}

.customer-inventory-btn {
  background: linear-gradient(0deg, rgba(128, 128, 128, 0.25) -17935.87%, rgba(128, 128, 128, 0.12) -15265.57%, rgba(128, 128, 128, 0.1) -12990.87%);
  border: 1px solid var(--alpha-40);
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  position: relative;
  width: 210px;
  height: 48px;  
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  color: var(--alpha-80);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  margin-top: 2rem;
}
.download-csv-card {
     height: 100%;
}

.csv_modal {  
  width: 700px;
  height: 352px;
  background: #FFFFFF;
  border-radius: 15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 300;
}

.csv_download_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 1.25px;
  color: var(--alpha-black-500);
  text-align: center;
}

.download_csv .closebtn {
    color: black;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
}

.select-download-duration {
    padding: 0 3rem;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
}

.download-btn button {
    width: 226px;
    height: 48px;
    background: linear-gradient(0deg, rgba(128, 128, 128, 0.25) -17935.87%, rgba(128, 128, 128, 0.12) -15265.57%, rgba(128, 128, 128, 0.1) -12990.87%);  
    border: 1px solid var(--alpha-40);
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

}

.download-form1 label, .download-form2 label {
  font-family: Circular Std;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.75px; 
  color: var(--alpha-80);
  margin-bottom: .8rem;
}


.range-holder {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.download-form1 input, .download-form2 input {
    width: 248px;
    height: 50px;  
    border: 1px solid var(--alpha-40);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 0 .5rem;
}
.download-form1, .download-form2 {
    display: flex;
    flex-direction: column;
}
.delete__modal__body {
   padding: 2rem;
}
.deletebtn {
  cursor: pointer;
}

.delete-header {
 font-family: Inter;
 font-style: normal;
 font-weight: 600;
 font-size: 16px;
 line-height: 16px;
 letter-spacing: 1.25px;
 color: var(--alpha-black-500);
 text-align: center;
}

.cta-btn-delete {
  display: flex;
  width: 350px;
  justify-content: space-between;
  margin: 2rem auto;
}

.delete-confirm {
  width: 230px;
  height: 48px;
  border: 1px solid var(--alpha-red);
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.2));
  border-radius: 5px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: var(--alpha-red);
}

.delete-cancel {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: var(--alpha-black);
    border: none;
    outline: none;
    background: #FFFFFF;
}


.order-table td:first-child {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    color: var(--alpha-blue-back);
}

.order-table td:last-child {
    color: var(--alpha-blue-back);
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
}

.order_content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.3px solid var(--alpha-10);
    padding: 1rem 1.5rem;
    cursor: pointer;
}
.todays_order_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.3px solid var(--alpha-10);
  padding: 1rem 1.5rem;
  cursor: pointer;
}
.todays_order_content .todays_ordered_prod_name {
     display: flex;
     flex-direction: column;
     align-items: center;
     font-size: 11px !important;
}
.todays_order_content .todays_ordered_prod_name div:first-child {
     font-weight: 700;
     font-family: Inter;
     font-style: normal;
     font-size: 10px;
     line-height: 15px;
     letter-spacing: -0.02em;
}
.todays_order_content .todays_ordered_prod_name div:last-child {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: -0.02em;
    color: var(--alpha-black-500);
}

.todays_order_list_content_details {
    width: 95%;

}
.order__id, .order__qty {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.02em;
    color: var(--alpha-black-500);
}
.order__id strong, .order__qty strong {
  font-weight: 700 !important; 
}
.order__date {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: -0.02em;
  color: var(--alpha-40);
}
.loader_position {
     width: 100%;;
     height: 100%;
     display: flex;
     justify-content: center;
     align-items: center;
}

.item_list_content {
  width: 90%;
  margin: 1rem auto;
  max-height: 0;
  overflow: hidden;
  transition: all .5s cubic-bezier(0,1,0,1);
}

.item_list_content.show {
  height: auto;
  max-height: 9999px;
  transition: all .5s cubic-bezier(1,0,1,0);
}

.todays_order_list_content {
  width: 100%;
  margin: 1rem auto;
  max-height: 0;
  overflow: hidden;
  transition: all .5s cubic-bezier(0,1,0,1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.todays_order_list_content.show {
  height: auto;
  max-height: 9999px;
  transition: all .5s cubic-bezier(1,0,1,0);
}

.sub-item-header {
  width: 100%;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  font-style: normal;
  color: var(--alpha-80);
  text-align: center;
  font-family: 'Circular Std';
  line-height: 20px;

}
button:disabled {
     cursor: not-allowed !important;
}
dialog::backdrop {
    background: rgba(0,0,0,0.7);
}
.confirm-delivery {
  margin-left: 0.75rem;
  
}
.ordered-status button:nth-child(2), .processing-status button:nth-child(2), .shipped-status button:nth-child(2) {
  background: var(--alpha-blue-back) !important;
  border: 1px solid var(--alpha-blue-back) !important;
}
.order-status-pending, .order-status-accepted, .ordered-status, .processing-status {
  color: #ffffff;
}
.ordered-status button{
  background: var(--alpha-primary) !important;
  border-radius: 5px;
  border: 1px solid var(--alpha-primary) ;
  font-family: 'Inter';
  color: var(--text-white);
  text-align: center;
  padding: .3em .7em;
  font-weight: 700;
  font-style: normal;
  font-size: 12px;
}
.processing-status button {
  background: var(--alpha-info-900) !important;
  border-radius: 5px;
  border: 1px solid var(--alpha-info-900);
  font-family: 'Inter';
  color: var(--text-white);
  text-align: center;
  padding: .3em .7em;
  font-weight: 700;
  font-style: normal;
  font-size: 12px;
}
.delivered-status button {
  background: var(--success) !important;
  border-radius: 5px;
  border: 1px solid var(--success);
  font-family: 'Inter';
  color: var(--text-white) !important;
  text-align: center;
  padding: .3em .7em;
  font-weight: 700;
  font-style: normal;
  font-size: 12px;
}

.shipped-status button {
    background: var(--alpha-violet) !important;
    border-radius: 5px;
    border: 1px solid var(--alpha-violet);
    font-family: 'Inter';
    color: var(--text-white) !important;
    text-align: center;
    padding: .3em .7em;
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
}
.cancelled-status button {
  background: var(--text-gray-600) !important;
  border-radius: 5px;
  border: 1px solid var(--text-gray-600);
  font-family: 'Inter';
  color: var(--text-white) !important;
  text-align: center;
  padding: .3em .7em;
  font-weight: 700;
  font-style: normal;
  font-size: 12px;
}
.order-status-pending button{
    width: 80px;
    padding: .2em .5em;
    background: var(--alpha-info-900) !important;
    border-radius: 5px;
    border: 1px solid var(--alpha-info-900);
    color: var(--text-white);
    text-align: center;
    font-style: normal;
    font-size: 12px;
    font-weight: 700;
}
.order-status-accepted button{
  width: 80px;
  background: var(--success) !important;
  border-radius: 5px;
  border: 1px solid var(--success) ;
  font-family: 'Inter';
  color: var(--text-white);
  text-align: center;
  padding: .2em .5em;
  font-weight: 700;
  font-style: normal;
  font-size: 12px;
  
}

.order-status-rejected button {
  width: 80px;
  background: var(--alpha-red) !important;
  border-radius: 5px;
  border: 1px solid var(--alpha-red) ;
  font-family: 'Inter';
  color: var(--text-white);
  text-align: center;
  padding: .2em .5em;
  font-weight: 700;
  font-style: normal;
  font-size: 12px;
}

.order-details button {
    background: linear-gradient(0deg, rgba(128, 128, 128, 0.25) -17935.87%, rgba(128, 128, 128, 0.12) -15265.57%, rgba(128, 128, 128, 0.1) -12990.87%);
    border: 1px solid var(--alpha-40);
    border-radius: 5px;
    font-family: 'Inter';
    color: var(--alpha-80);
    padding: .2em .5em;
    font-weight: 700;
    font-style: normal;
    font-size: 11px;
}

.order-details a {
  background: linear-gradient(0deg, rgba(128, 128, 128, 0.25) -17935.87%, rgba(128, 128, 128, 0.12) -15265.57%, rgba(128, 128, 128, 0.1) -12990.87%);
  border: 1px solid var(--alpha-40);
  border-radius: 5px;
  font-family: 'Inter';
  color: var(--alpha-80);
  padding:  .5em;
  font-weight: 700;
  font-style: normal;
  font-size: 12px; 
  text-decoration: none;
}
.loader-page {
     width: 100%;
     height: 100vh;
     display: flex;
     align-items: center;
     justify-content: center;
}



.loader {
  border-radius: 50%;
  width: 120px;
  height: 120px;
}

.loader img {
  height: 120px;
  width: 120px;
  animation: pulse 1s linear infinite;
}

.no-record-found {
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 700;
    color: var(--alpha-blue-back);
}


.subcategory_content {
  width: 90%;
  margin: 1rem auto;
  max-height: 0;
  overflow: hidden;
  transition: all .5s cubic-bezier(0,1,0,1);
}

.subcategory_content.show {
height: auto;
max-height: 9999px;
transition: all .5s cubic-bezier(1,0,1,0);
}
.modal-pop-up {
     width: 850px;
     height: auto;
     opacity: 0;
     z-index: 999999999;
     background: #FFFFFF;
     border-radius: 10px;
     position: fixed;
     height: 500px;
     overflow-y: auto;
}
.modal-pop-up.show{
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
    transition: opacity .5s ease-in-out;
    opacity: 1;
  
   
}

.popup-header-container {
    border-bottom: 0.5px solid var(--alpha-40);
}

.popup-header-container {
    padding: 1rem;
    color: var(--alpha-black);
    display: flex;
    align-items: center;
}
.popup-header-container .popup-back-arrow {
    cursor: pointer;
    font-size: 20px;
    font-weight: 700;
}
.popup-header-container.unverified-items {
   justify-content: space-between !important;
}
.popup-header-container div:first-child {
     display: flex;
     align-items: center;
}
.popup-header-container button {
    border: 1px solid var(--alpha-success);
     background: var(--alpha-success);
     color: var(--text-white);
     padding: 0.5rem 1rem;
     border-radius: 5px;
     box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.order-item-name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 450;
    font-size: 11px;
    line-height: 24px;
    letter-spacing: -0.02em;    
    color: var(--alpha-black-500)
}
.order-item-name-d {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 1.25px;    
    color: var(--alpha-black-500);
    margin-left: 1.5rem;
}
.popup-body {
  box-sizing: border-box;
  padding: 1rem 3rem;
}
.image-data {
    width: 100%;
    height: 230px;
    border-radius: 5.5748px;
    padding: .2rem;
    border: 1px solid var(--alpha-40);
}
.image-data img {
     width: 100%;
     height: 100%;
}

.image-data.recent-order-img {
     width: 100%;
     height: 100%;
     object-fit: contain;
}
.image-data.recent-order-img img {
  width: 100%;
  height: 100%;
}
.color-size-sec {
    display: flex;
     width: 100%;
     margin: 1rem auto;
     justify-content: space-around;
}
.color-size-todays-sec {
   display: flex;
   width: 230px;
   margin: 1rem auto;
   justify-content: space-around;
}
.img-col-size-sec {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.product-color-oms {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 20px;    
    color: var(--alpha-80);
  
}
.size-oms {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  letter-spacing: 1px;
  color: var(--alpha-blue-back);
}

.waybill-header-details {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: var(--alpha-80)
  
}
.waybill-no-details {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.02em;
  color: var(--alpha-black-500);
}
.waybill-no-details.payment-type {
    color: #849614 !important;
}
.waybill-no-details.email-address a{
  text-decoration-line: underline;
  color: #0A79C9;
}

.text-order {
     width: auto;
     padding: .5rem;
     border-radius: 5px;
     border: none;
     color: var(--text-white);
     background: var(--alpha-primary) !important;
}
.text-pending {
  width: auto;
  padding: .5rem;
  border-radius: 5px;
  border: none;
  color: var(--text-white);
  background: var(--alpha-violet) !important;
}
.text-rejected {
  width: auto;
  padding: .5rem;
  border-radius: 5px;
  border: none;
  color: var(--text-white);
  background: var(--alpha-red) !important;
}
.text-processing {
    width: auto;
    padding: .5rem;
    border-radius: 5px;
    border: none;
    background: var(--alpha-info-900) !important;
}
.text-transit {
  width: auto;
  padding: .5rem;
  border-radius: 5px;
  border: none;
  background: var(--alpha-violet) !important;
}
.text-delivered {
  width: auto;
  padding: .5rem;
  border-radius: 5px;
  border: none;
  background: var(--success) !important;
  color: #FFF;
}

.multiselectContainer {
     border: none !important;
     width: 80% !important;
}
.searchWrapper {
  border: none !important;
  border-radius: 0px !important;
  border-bottom: 1px solid var(--alpha-40) !important;
  width: 80%;

}
  .chip{
  	background: var(--alpha-gray-400) !important;
    color: var(--text-white) !important;
  }
  .optionContainer{
	   border: 1px solid var(--alpha-40) !important;
     width: 80%;
  }
  .multiSelectContainer li:hover, .multiSelectContainer li:active, .multiSelectContainer li:focus {
    background: var(--alpha-green) !important;
    color: #fff;
    cursor: pointer;
}
  .optionContainer option{
	  color: var(--alpha-green) !important;
  }

  .icon_down_dir::before {
       display: none !important;
  }

  .close-icon-select {
    margin-left: .3rem !important;
    cursor: pointer !important;
    color: red !important;
  
  }

  .remittance-section {
     width: 80%;
     text-align: left;
  }
  .remittance-text {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 20px;    
    color: var(--alpha-80);
    margin: 1rem 0 0 0;
  }
 .remittance-label {
   display: inline-flex !important;
   margin-top: 1rem;
   align-items: center;
   cursor: pointer;
   
 }
 .remittance-label span {
      font-family: 'Circular Std';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.0125em;
      color: var(--alpha-80)
 }
.remittance_input {
    display: none;
}
.remittance_radio {
    width: 1.25em;
    height: 1.25em;
    border: 1.5px solid var(--alpha-80);
    border-radius: 50%;
    margin-right: 10px;
    box-sizing: border-box;
    padding: 2px;
}
.remittance_radio::before {
     content: "";
     height: 100%;
     width: 100%;
     display: block;
     background: var(--alpha-80);
     border-radius: 50%;
     transform: scale(0);
     transition: transform 0.15s;
}

.remittance_input:checked +  .remittance_radio::before  {
    transform: scale(1);
}
.add-category {
   display: flex;
   width: 100%;
   justify-content: flex-end;
   align-items: center;
}
.view-sub-categories {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  
  color: var(--alpha-80);
  cursor: pointer;
}

.all-sub-category-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.all-sub-category-header .headers {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 20px; 
    color: var(--alpha-80);
    margin-bottom: .5rem;
}

.all-sub-category-header .edit-delete {
     display: flex;
     align-items: center;
     justify-content: space-between;
     width: 10%;
}
/* .all-sub-category-header .edit-delete .headers:nth-child(odd){
    margin-right: 2.5rem;
    flex: 1;
} */

.sub-category-list {
    display: flex;
    padding: 1rem 0;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--alpha-10);
}
.subcat-name, .subcat-active {
  flex: 1;
}

.subcat-name {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.02em;
  
  /* Alpha Black */
  
  color: #1A1A1A;
}

.subcat-active {
    margin-left: 4.8rem;
}
.subcat-del {
  display: flex;
  align-items: center;
  width: 10%;
  justify-content: space-between;
}
.type_listing {
     width: 80%;
     display: flex !important;
     font-family: 'Circular Std';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}
.save-con.complete-icon {
     color: var(--alpha-blue-back);
}
.tooltip-holder {
     display: flex;
     align-items: center;
     gap: 1rem;
}
input[type=checkbox] {
  accent-color: #212529;
  width: 17px;
  height: 17px;
  
}
@keyframes pulse {
    0% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
  }
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
      -webkit-transform: scale(1);
    transform: scale(1);
  }
}


@-webkit-keyframes pulse {
    0% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
  }
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
      -webkit-transform: scale(1);
    transform: scale(1);
  }
}

