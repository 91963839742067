.logo img{
    width: 80px;
    height: 60px;
    margin-left: 2rem;
    margin-top: .2rem;
}

.username {
    color: var(--text-white);
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;

}
.caret_btn {
     transition: all .3s ease-in-out;
}
