.month_select {
     height: 30px;
}
.card__title_revenue {
     display: flex;
     float: right;
     align-items: center;
     gap: 0.8em;
}
.month_select:focus {
    outline: none !important;
}
