.setting-page-link {
    position: relative;
}
.setting-page-link svg {
    color: var(--text-white);
    cursor: pointer;
    font-size: 17px;
    align-self: center;
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-70%, -50%);

}

.settings-active.active {
    background: var(--alpha-red-50);
    border-radius: 50%;
    padding: 0.2rem .75rem;
    text-align: center;
}
.settings-active {
    position: relative;
    
}

.settings-active svg {
    color: var(--text-white);
    cursor: pointer;
    font-size: 15px;
    align-self: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}
.has-search, .main_form {
     position: relative;
}
.clear-search {
    position: absolute;
    top: -1.1em;
    right: 0.5em;
    padding: 2px;
}
.clear-search:hover {
    color: var(--alpha-red);
    cursor: pointer;
}