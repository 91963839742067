
.nav-progress ul li{
    padding: .5rem 0;
}

.alpha__tracker-inprogress .progress-count{
    width: 25px !important;
    height: 25px !important;
    background: var(--alpha-white);
    border: 1.5px solid var(--alpha-lemon);
    animation: animate 1.5s linear infinite;
    opacity: 0;
   
}
.alpha__tracker-inprogress .progress-count::after{
    width: 15px !important;
    height: 15px !important;
    animation-delay: 0.5s;
}
.alpha__tracker-inprogress .progress-label span{
    margin-top: 5px !important;
    margin-left: 5px;
    font-size: 10px !important;
    color: var(--alpha-lemon) !important;
}
    .delivery__track--progress-desktop {
         display: flex;
         justify-content: center;
     
    }
    .delivery__track--progress-desktop .nav__progress-desktop {
         display: flex;
         margin: auto;
         overflow-x: auto;
    }
    .delivery__track--progress-desktop .nav__progress-desktop .step__wizard-list {
        display: flex;
        padding: 10px 0px;
        position: relative;
        z-index: 10;
        list-style: none;
    }
   .delivery__track--progress-desktop .nav__progress-desktop::-webkit-scrollbar{
        height: .25em;
      } 
      
     .delivery__track--progress-desktop .nav__progress-desktop::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px var(--alpha-blue-back);
      }
      
      
     .delivery__track--progress-desktop .nav__progress-desktop::-webkit-scrollbar-thumb {
        background-color: var(--alpha-40);
        outline: 1px solid var(--alpha-10);
        border-radius: 8px;
      }

    .step__wizard-list .step__wizard-item .progress-label{
            display: flex;
            flex-direction: column;
            margin-top: 5px;
    }
    .step__wizard-list .step__wizard-item .progress-label span:first-child {
        font-family: 'Circular Std';
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 15px;
        width: 100%;        
        letter-spacing: 0.5px;        
        color: var(--alpha-lemon);
    }
    .step__wizard-list .step__wizard-item .progress-label span:last-child {
        font-family: 'Circular Std';
        font-style: normal;
        font-weight: 700;
        font-size: 7px;
        line-height: 6px;
        color: #8E91A2;
        margin-left: 0;
    }
    .step__wizard-list .step__wizard-item + .step__wizard-item::after {
         content: "";
         position: absolute;
         left: 0;
         transform: translate(-50%, -50%);
         top: 12px;
         background: var(--alpha-lemon);
         width: 100%;
         height: 2px;
         z-index: -10;
    }
    .step__wizard-list .step__wizard-item  .progress-count {
         width: 25px;
         height: 25px;
         display: flex;
         align-items: center;
         justify-content: center;
         border-radius: 50%;
         font-weight: 600;
         margin: 0 auto;
         position: relative;
         z-index: 10;
         color: var(--alpha-white);
         font-size: 14px;
    }
    .step__wizard-list .step__wizard-item  .progress-count::after {
        content: "";
        width: 25px;
        height: 25px;
        background: var(--alpha-lemon);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        border-radius: 50%;
        z-index: -10;
    }

    .nav-progress ul {
        display: flex;
        text-decoration: none;
    }
    .nav-progress ul li {
        margin: 0;
    } 
   
.track-item.track-progress{
       margin-left: 2rem;
       width: 100%;
       overflow-x: auto;
}


.track-img {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 0.5rem;
    position: relative;
}
.track-img .tracking__section-img {
      width: 100%;
      min-height: 250px;
      max-height: 360px;
      border-radius: 0px 0px 6px 6px;
      background: var(--alpha-10);
      display: flex;
      gap: 0.5rem;
      position: relative;
    
}

.track-progress {
    margin-left: 0rem;
}

.delivery-track-progress {
   display: flex;
}


.delivery-track-progress .nav-progress .step__wizard--list-mobile {
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
    position: relative;
    z-index: 10;
    list-style: none;
}
.step__wizard--list-mobile .step__wizard--item-mobile  + .step__wizard--item-mobile {
      position: relative;
}
.delivery-track-progress .nav-progress .step__wizard--list-mobile .step__wizard--item-mobile {
      display: flex;
      gap: 0.75rem;
      max-height: 100%;
      width: 100%;
      min-height: 100px;
      position: relative;
}

.step__wizard--list-mobile .step__wizard--item-mobile .progress__label-mobile{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      flex: 1;
}
.step__wizard--list-mobile .step__wizard--item-mobile .progress__label-mobile span:first-child {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;    
  letter-spacing: 0.5px;        
  color: var(--alpha-blue-back);
}
.step__wizard--list-mobile .step__wizard--item-mobile .progress__label-mobile span:last-child {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 7px;
  line-height: 6px;
  color: #8E91A2;
}
.step__wizard--list-mobile .step__wizard--item-mobile  + .step__wizard--item-mobile::after {
   content: "";
   position: absolute;
   bottom: 50%;
   margin-left: 0.75em;
   transform: translateY(-40%);
   background: var(--alpha-blue-back);
   height: 100%;
   width: 2px;
   z-index: -10;
}
.step__wizard--list-mobile .step__wizard--item-mobile .progress__count-mobile {
   width: 25px;
   height: 25px;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 50%;
   font-weight: 600;
   position: relative;
   z-index: 10;
   color: var(--alpha-white);
   font-size: 14px;
}
.step__wizard--list-mobile .step__wizard--item-mobile .progress__count-mobile::after {
  content: "";
  width: 25px;
  height: 25px;
  background: var(--alpha-blue-back);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  border-radius: 50%;
  z-index: -10;
}

.nav-progress ul li{
    padding: .5rem 0;
}

.tracking-loading {
    height: 100%;
    min-height: 50vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tracking-error-message {
    height: 100%;
    min-height: 50vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--alpha-red);
    font-size: 12px;
    font-family: 'Circular Std';
    font-weight: bold;
    letter-spacing: 1.25px;
 
}
.tracking-error-message p {
    width: 95%;
    padding: 1rem;
    border-radius: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
}

.nav-progress ul .ordered-disabled-mobile::after {
    position: absolute;
    content: "";
    height: 100%;
    top: 27px;
    left: 9px;
    width: 2px;
    border: 1px  solid rgba(0, 0, 0, 0.1);;
    background: 1px solid rgba(0, 0, 0, 0.1);;
}
.delivery__details-page {
     width: 85%;
     margin: auto !important;
     padding: 1rem 0;
}




.alpha__tracker-inprogress .progress-count{
    width: 25px !important;
    height: 25px !important;
    background: var(--alpha-white);
    border: 1.5px solid var(--alpha-lemon);
    animation: animate 1.5s linear infinite;
    opacity: 0;
    backface-visibility: hidden;
}
.alpha__tracker-inprogress .progress-count::after{
    width: 15px !important;
    height: 15px !important;
    animation-delay: 0.5s;
}

@keyframes animate {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: scale(1.2);
        opacity: 0;
    }
}

/* @media only screen and (max-width: 280px){
    .step__wizard--list-mobile .step__wizard--item-mobile  + .step__wizard--item-mobile::after {
         margin-left: 0.5em;
    }
} */



   
    .track-progress {
        margin-left: 0rem;
    }

    
.track-img {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: initial;
    gap: 0;
}



    .tracker-section {
        position: relative;
        padding: 1rem 0;
        width: 100%;
       
    }
    .delivery__track--progress-desktop {
         display: flex;
         justify-content: center;
     
    }
    .delivery__track--progress-desktop .nav__progress-desktop {
         display: flex;
         margin: auto;
         overflow-x: auto;
    }
    .delivery__track--progress-desktop .nav__progress-desktop .step__wizard-list {
        display: flex;
        padding:0px;
        position: relative;
        z-index: 10;
        list-style: none;
    }
   .delivery__track--progress-desktop .nav__progress-desktop::-webkit-scrollbar{
        height: .5em;
      } 
      
     .delivery__track--progress-desktop .nav__progress-desktop::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px var(--alpha-blue-back);
      }
      
      
     .delivery__track--progress-desktop .nav__progress-desktop::-webkit-scrollbar-thumb {
        background-color: var(--alpha-40);
        outline: 1px solid var(--alpha-10);
        border-radius: 8px;
      }

    .step__wizard-list .step__wizard-item{
          padding: 0px;
          flex-basis: 0;
          -webkit-box-flex: 1;
          -ms-flex-positive: 1;
          flex-grow: 1;
          max-width: 100%;
          display: flex;
          flex-direction: column;
          text-align: center;
          min-width: 150px;
          position: relative;
    }
    .step__wizard-list .step__wizard-item .progress-label{
            display: flex;
            flex-direction: column;
            margin-top: 5px;
    }
    .step__wizard-list .step__wizard-item .progress-label span:first-child {
        font-family: 'Circular Std';
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 15px;
        width: 90%;        
        letter-spacing: 0.5px;        
        color: var(--alpha-lemon);
    }
  
    .step__wizard-list .step__wizard-item + .step__wizard-item::after {
         content: "";
         position: absolute;
         left: 0;
         transform: translate(-50%, -50%);
         top: 12px;
         background: var(--alpha-lemon);
         width: 100%;
         height: 2px;
         z-index: -10;
    }
    .step__wizard-list .step__wizard-item  .progress-count {
         width: 25px;
         height: 25px;
         display: flex;
         align-items: center;
         justify-content: center;
         border-radius: 50%;
         font-weight: 600;
         margin: 0 auto;
         position: relative;
         z-index: 10;
         color: var(--text-white) !important;
         font-size: 14px;
    }
    .step__wizard-list .step__wizard-item  .progress-count::after {
        content: "";
        width: 25px;
        height: 25px;
        background: var(--alpha-lemon);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        border-radius: 50%;
        z-index: -10;
    }
 

    .nav-progress ul {
        display: flex;
        text-decoration: none;
    }
    .nav-progress ul li {
        margin: 0;
    } 
   
 
   .item-tracking-layout {
    display: flex;
    width: 100%;
    margin: auto;
}

.track-item.track-progress{
       width: 100%;
}

