.card__role_tile {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    font-family: Circular Std;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    color: var(--alpha-80);
}
.role__details {
    display: flex;
    align-items: center;
}

.admin_remove {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding: .5rem 1rem;
    letter-spacing: 1.25px;
    color: var(--alpha-red);
    background: rgba(237, 50, 55, 0.25);
    border: 1px solid var(--alpha-red);
    box-sizing: border-box;
    border-radius: 5px;
}

.admin_edit {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding: .5rem 1rem;
    background: linear-gradient(0deg, rgba(128, 128, 128, 0.25) -17935.87%, rgba(128, 128, 128, 0.12) -15265.57%, rgba(128, 128, 128, 0.1) -12990.87%);
    color: var(--alpha-80);
    
    border: 1px solid var(--alpha-40);
    box-sizing: border-box;
    border-radius: 5px;
}

.category_name_user {
    width: 44%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}

.edit_delete {
    display: flex;
    align-items: center;
    margin-left: 16.5rem;
}

.edit_delete .admin_remove {
    margin-left: .85rem;
}