.data_heading {
    padding: 1rem 1rem; 
    border-bottom: 1px solid var(--alpha-40);
    display: grid;
    grid-template-columns: repeat((2, minmax(150px, 1fr)));
    width: 100%;
}

.data_heading .button_export {
    background: linear-gradient(0deg, rgba(128, 128, 128, 0.25) -17935.87%, rgba(128, 128, 128, 0.12) -15265.57%, rgba(128, 128, 128, 0.1) -12990.87%);
    border: 1px solid var(--alpha-40);
    box-sizing: border-box;
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 25px;
    letter-spacing: 1.25px;
    color: var(--alpha-80);
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.table-header {
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    align-items: center;
    color: var(--alpha-80);
    width: 100%;
    padding: 1rem 1rem .5rem .5rem;


}
.table>:not(:last-child)>:last-child>* {
    border-bottom-color: var(--alpha-10) !important;
}
.view_details {
    background: linear-gradient(0deg, rgba(128, 128, 128, 0.25) -17935.87%, rgba(128, 128, 128, 0.12) -15265.57%, rgba(128, 128, 128, 0.1) -12990.87%);
    border: 1px solid var(--alpha-40);
    box-sizing: border-box;
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 1.25px;
    color: var(--alpha-80);
    margin-left:  1rem;
    width: 102.73px;
}

.cancel_details {
    background: var(--text-white);
    border: 1px solid var(--alpha-80);
    box-sizing: border-box;
    border-radius: 5px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 1.25px;
    color: var(--alpha-80);
    margin-left:  1rem;
    width: 102.73px;
}

.header_merchant {
    margin-left: 6rem;
}

.new_details {
    margin-left: 8rem !important;
}
.header_alpha {
    margin-left: 3rem;
}
.header_category {
    margin-left: 1rem;
}

.header_prod_name {
    margin-left: 1.7rem;
}
.header_price {
    margin-left: 3.5rem;
}
.header_payment {
    margin-left: 1rem;
}
.header_buyer {
    margin-left: .5rem;
}
tbody > tr > td {
    padding: 1rem !important;
}
tbody > tr > td {
    color: var( --alpha-black-500);
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: -0.02em;
 
}
.paginate {
    display: flex;
    align-items: center;
    justify-content: center;
}
.paginate_reuse {
     margin-left: 7rem;
}
.page_number {
    margin: auto 1rem;
}
.page_number input {
      width: auto;
      max-width: 18%;
      padding-left: 1rem;
      background: var(--text-white);
      border: 1px solid var(--alpha-40);
      box-sizing: border-box;
      border-radius: 5px;
      align-items: center;
}
.ant-pagination-item-active, .ant-pagination-item {
    background:  linear-gradient(0deg, rgba(128, 128, 128, 0.25) -17935.87%, rgba(128, 128, 128, 0.12) -15265.57%, rgba(128, 128, 128, 0.1) -12990.87%);
    border: none !important;
    color: var(--text-dark);
}
.ant-pagination {
    background: linear-gradient(0deg, rgba(128, 128, 128, 0.25) -17935.87%, rgba(128, 128, 128, 0.12) -15265.57%, rgba(128, 128, 128, 0.1) -12990.87%);
    border: 1px solid var(--alpha-40);
    box-sizing: border-box;
    border-radius: 5px;
}

.ant-pagination-next, .ant-pagination-previous {
     border: none !important;
   
}
.anticon {
    color: #000000;
}


/* orders */
.header_code {
    margin-left: .5rem;
}

.header_name{
    margin-left: 2rem;
}
/* .header_category {
    margin-left: 3rem;
} */
.header_category {
    margin-left: 1.5rem;
}

.header_seller {
    margin-left: 1.3rem;
}
.header_seller_new {
    margin-left: 3rem;
}
.header_product {
    margin-left: 1.7rem;
}
.header_status {
    margin-left: 2rem;
}


.ordered, .declined, .proccess, .shipped, .delivered {
    color: var(--text-white);
    width: 100px;
    border-radius: 5px;
}
.ordered {
    background: var(--alpha-primary);
    border: 1px solid var(--alpha-primary);
}

.declined {
    background: var(--alpha-red-800);
    border: 1px solid var(--alpha-red-800);
}

.proccess {
    background: var(--alpha-info-900);
    border: 1px solid var(--alpha-info-900);
}
.shipped {
    background: var(--alpha-violet);
    border: 1px solid var(--alpha-violet)
}

.delivered {
    background: var(--success);
    border: 1px solid var(--success);
}

@media screen and (min-width: 48em) {
    .data_heading {
        padding: 1rem 1rem; 
        border-bottom: 1px solid var(--alpha-40);
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    } 
}