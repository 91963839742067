
.delivery-track-progress-desktop {
    width: 90% !important;
    display: flex;
    justify-content: center !important;
    margin-left: auto;
    margin-right: auto;
}
.nav-progress-desktop {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

}

.nav-progress-desktop ul {
    list-style: none;
    width: 100%;
    display: flex;
    margin-top: 1rem;
    margin-left: 0 !important;
    padding: 0 !important;
    align-items: flex-start;
    justify-content: center;
}
.circle-track-desktop, .circle-track-process-desktop {
    width: 25px;
    height: 25px;
    background: var(--alpha-lemon) !important;
    color: var(--text-white);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: relative;
    font-size: 10px;
}
.ordered-incheck-desktop, .ordered-incheck-progress-desktop, .ordered-incheck-transit-desktop, .disabled-incheck-progress-desktop {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.ordered-incheck-desktop span:first-child, .ordered-incheck-progress-desktop span:first-child {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.5px;
    color: var(--alpha-lemon);
}
.ordered-incheck-desktop span:last-child, .ordered-incheck-progress-desktop span:last-child {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 7px;
    line-height: 6px;
    color: #8E91A2;
}
.ordered-incheck-transit-desktop span:first-child, .delivered-line:first-child {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.5px;
    color: var(--alpha-lemon);
}
.ordered-incheck-transit-desktop span:last-child {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 7px;
    line-height: 6px;
    color: var(--alpha-lemon);
}
.circle-track-ongoing-desktop {
    position: relative;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: var(--alpha-lemon) !important;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px;
}
.circle-track-ongoing-desktop::after {
    content: "";
    width: 100%;
    height: 100%;
    background: var(--text-white);
    border-radius: 50%;
}
.circle-track-disabled-desktop {
     width: 25px;
     height: 25px;
     background: var(--alpha-40);
     border-radius: 50%;
     box-sizing: border-box;
     position: relative;
}

.disabled-incheck-progress-desktop span:first-child {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.1);
}
.disabled-incheck-progress-desktop span:last-child {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 7px;
    line-height: 6px;
    color: rgba(51, 51, 51, 0.25);
}


.ordered-progress-desktop, .processed-progress-desktop, .intransit-progress-desktop, .disabled-progress-desktop, .disabled-desktop, .delivered-line {
    width: 200px;
    position: relative;
}

.ordered-progress-desktop::after, .processed-progress-desktop::after {
    content: "";
    width: 84%;
    top: 22%;
    left: 58%;
    height: 1px;
    background: var(--alpha-lemon);
    position: absolute;
    transform: translate(-50%, -20%);

}
.delivered-line::after  {
    content: "";
    width: 0 !important;
    top: 0 !important;
    left: 0 !important;
    height: 0 !important;
    background: none !important;
    position: absolute !important;
}
.intransit-progress-desktop::after {
    content: "";
    width: 84%;
    top: 30%;
    left: 58%;
    height: 1px;
    position: absolute;
    transform: translate(-50%, -20%);
    background: var(--alpha-40);
}
.disabled-desktop::after {
    content: "";
    width: 84%;
    top: 30%;
    left: 58%;
    height: 1px;
    position: absolute;
    transform: translate(-50%, -20%);
    background: var(--alpha-40);
}

.track-progress .tracked-item-name {
    font-weight: 700;
    font-size: 28px;
    line-height: 35px;
    

}

.display-status .status-date {
    font-weight: 700;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0.5px;
    color: #8E91A2;
    margin-left: 15px !important;
}



