.tabs_container ul {
    display: flex;
    justify-content: space-between;
    padding: 1rem 3rem;
    list-style: none;
    background: var(--text-white);
    border-radius: 10px;
}

.tabs_container ul > li  {
    color: var(--alpha-40);
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 1.5px;
    padding: .5rem 1.5rem;
    cursor: pointer !important;
}
.tab_color a{
    color: var(--alpha-40);
}

.tabs_active {
    background: var(--alpha-red-35);
    color: var(--alpha-red) !important;
    border-radius: 15px;
    transition: all .3s ease-in;
}
.tabs_active a {
    color: var(--alpha-red) !important;
}
.sort {
    background: linear-gradient(0deg, rgba(128, 128, 128, 0.25) -17935.87%, rgba(128, 128, 128, 0.12) -15265.57%, rgba(128, 128, 128, 0.1) -12990.87%);
    border: 1px solid var(--alpha-40);
    box-sizing: border-box;
    border-radius: 5px;
    width: 95.75px;
    height: 25px;
    padding: auto .5rem;
    cursor: pointer;
}

.ctas-button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.button_sort {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: center;

}

.btn_add_category {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2rem !important;
    width: 260px;
    padding: 0 5px;
    height: 40px;
    background: linear-gradient(0deg, rgba(128, 128, 128, 0.25) -17935.87%, rgba(128, 128, 128, 0.12) -15265.57%, rgba(128, 128, 128, 0.1) -12990.87%);
    border: 1px solid var(--alpha-40);
    border-radius: 5px;
    
}

.header_order_title {
    width: 100%;
}

.icon_one {
    margin-right: 1rem;
}

.icon_add {
    margin-right: 5px !important;
}

.sortable_dropdown {
   position: absolute;
   right: 1rem;
   text-align: left;
   line-height: 1.5;
   padding: 1rem;
   z-index: 100;
   width: 158.59px;
   height: 74px;
   background: var(--text-white);
   box-shadow: 0px 0px 4px #00000040;
   border-radius: 5px;
   cursor: pointer;
   display: none;

}

.activate_sort {
    display: block;
}


.divider {
     width: 100%;
     height: 3px;
     border-bottom: 1px solid #808080;
   
}