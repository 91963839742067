.menu_items {
      position: relative;
}
.dashoard_content {
    position: relative;
    top: 0;
}
.users_update p {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 1px;
    text-align: center;
}

.update_header {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 16px;
    letter-spacing: 1.25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.update_header .up {
    color: var(--success);
}
.update_header .down {
    color: var(--alpha-red);
}
.update_up {
     margin-top: -0.5rem;
}

.buttons {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1.25px;
    color: var(--alpha-80);
    background: linear-gradient(0deg, rgba(128, 128, 128, 0.25) -17935.87%, rgba(128, 128, 128, 0.12) -15265.57%, rgba(128, 128, 128, 0.1) -12990.87%);
    border: 1px solid var(--alpha-40);
    box-sizing: border-box;
    border-radius: 5px;
}

.card__header {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    padding: .5rem 0;
    letter-spacing: 1.25px;
    text-align: center !important;
    color: var(--alpha-black-500);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background: var(--text-white);
    border-bottom: 1px solid var(--alpha-40);
}

.card__title {
    margin-left: 67%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1.25px;
}
.month_select {
    background: linear-gradient(0deg, rgba(128, 128, 128, 0.25) -17935.87%, rgba(128, 128, 128, 0.12) -15265.57%, rgba(128, 128, 128, 0.1) -12990.87%);
/* Alpha 40 */
    border: 1px solid rgba(51, 51, 51, 0.4);
    box-sizing: border-box;
    border-radius: 5px;
    padding: 1rem auto;
}

.category {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;
    padding: 5px 2px

}

.category .category__name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    gap: 1em
}
.prod_card {
    font-family: "Circular Std";
    font-style: normal;
    font-weight: bold;
    font-size: 1em;
    line-height: 20px;
    color: var(--alpha-80);
    height: 380px;
    overflow-y: auto;

}
.prod_card_donutchart {
    height: 380px;
    width: 100%;
    overflow-y: auto;
  
}


.prod_card_chart {
    height: 380px;
    width: 100%;
    overflow-y: auto;
  
}
.prod_card_chart::-webkit-scrollbar, .prod_card::-webkit-scrollbar,.prod_card_donutchart::-webkit-scrollbar {
    width: 0.2em;
  }
   
  .prod_card_chart::-webkit-scrollbar-track, .prod_card::-webkit-scrollbar-track, .prod_card_donutchart::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }
   
  .prod_card_chart::-webkit-scrollbar-thumb, .prod_card::-webkit-scrollbar-thumb, .prod_card_donutchart::-webkit-scrollbar-thumb {
    background-color: var(--alpha-black-500);
    outline: 1px solid var(--alpha-blue-back);
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
  }
  .prod_card_chart::-webkit-scrollbar-thumb:hover, .prod_card::-webkit-scrollbar-thumb:hover, .prod_card_donutchart::-webkit-scrollbar-thumb:hover {
       background-color: var(--alpha-blue-back);
  } 
.category_total {
     background: var(--success) !important;
     color: var(--text-white);
     padding: .3rem .5rem;
     border-radius: 5px;
}

.top_products {
    width: 100%; 
    height: 100%;
}
.top_prod_name {
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: rgba(51, 51, 51, 0.8)
}

.top_prod_category {
    font-family: Inter;
    font-style: normal;
    font-weight: 450;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: -0.02em;
    color: var(--alpha-red);
}

.top_prod_price {
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #000000;
    float: right;
}

.top_prod {
    display: flex;
    gap: 1em;
    border-bottom: 1px solid var(--alpha-40);
    padding: 1em 0;
}
.top_prod .top_selling_img {
     width: 12%;
     height: 12%;
     /* border: 1px solid var(--alpha-10); */
     border-radius: 5px;
     background: url("../src/assets/shutterstock_1074927038\ 1.svg") no-repeat;
     background-position: center;
     background-size: cover;
     display: flex;
     justify-content: center;
     align-items: center;
    
}
.top_prod .top_selling_img img {
     width: 100%;
     height: 100%;
     object-fit: cover;
}
.store-img-cat {
     width: 25px;
     height: 25px;
     border: 1px solid var(--alpha-10);
     border-radius: 50%;
}
.store-img-cat img {
    width: 100%;
    height: 100%;
}
.store-name-cat {
    display: flex;
    flex-direction: column;
    gap: 0;
}
.store-name-cat div:first-child {
    font-family: Inter;
    font-style: normal;
    font-weight: 450;
    font-size: 8px;
    line-height: 13px;
    letter-spacing: 0.75px;
    color: var(--alpha-40);
}
.store-name-cat div:nth-child(2){
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 14px;
    letter-spacing: 0.75px;
    color: var(--alpha-blue-back);
}
.product-price_data {
    display: flex;
    flex-direction: column;
}
.top_prod_alpha_store_sec{
    display: flex;
    align-items: center;
    gap: 0.2em;
}
.top_selling_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 88%;

}
.top_selling_section .top-prod-name-price {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 1em;
}
.top_selling_section .top-prod-name-price .top_prod_alpha_code{
    font-family: Inter;
    font-style: normal;
    font-weight: 450;
    font-size: 9px;
    line-height: 14px;
    letter-spacing: -0.02em;

    /* Alpha black */

    color: var(--alpha-black-500);
}

.product_data .top_prod{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.star {
     font-size: 10px;
     font-weight: 400px;
     color: var( --alpha-gray-400);
}
.star:nth-child(n+2){
    margin-left: 2px;
}

.star1,.star2,.star3 {
    color: #FFCC4D
}

.see_more {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1.25px;
    margin-top: 16px;
    color: var(--alpha-40);
    cursor: pointer;
}
.see_more:hover {
    color: var(--alpha-blue-back);
}

.row_data {
     margin-top: -1rem;
}
.card_wrapper {
    padding-bottom: 1rem;
}
.card-revenue-header {
     background: var(--text-white) !important;
} 

.notification {
    position:relative;
    right: 0;
    top:0;
    height: auto;
    width: 100%;
    display: none;
    transition: top ease-in 0.3s;
    z-index: 500;

}
.notification_active {
   display: block;
}


.notification-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1rem;
}

.notification-header span:first-child {
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 20px;
    color: var(--alpha-black-500);
}
.underline_link {
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.25px;
    text-decoration-line: underline;
    cursor: pointer;
    color: var(--alpha-40);
}
.notification-dropdown {
    position: absolute;
    right: 0;
    top: -1.5rem;
    width: 350px;
    height: auto;
    background: var(--text-white);
    padding: 1.5rem;
    border-bottom-right-radius: 28px;
    border-bottom-left-radius: 28px;
    z-index: 10;
    box-shadow: 4px 4px 20px rgba(0,0,0,.5);

}
.icon {
    cursor: pointer;
    z-index: 10;
}

.overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background: rgba(0,0,0,.7) !important;
    opacity: 0;
    transition: opacity .3s ease-in;
    cursor: pointer;
}
.show_overlay {
    opacity: 1;
}
.notification_icon_active {
    color: var(--alpha-lemon) !important
  }

.notify {
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-bottom: 1px solid var(--alpha-10);
    padding: 1rem 0;
}
.notification-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.notify-icon {
    display: flex;
    align-items: center;
}

.notification_label {
    margin-bottom: -6px;
    margin-left: 7px;
}

.notification_label_old {
      margin-left: 7px;
      margin-top: 3px;
}

.notification_underline {
    border-bottom: 1px solid var(--alpha-10);
    padding-bottom: 1rem;
}
.notification-content {
     margin-left: 1.3rem;
     font-style: normal;
    font-weight: 450;
    font-size: 11px;
    line-height: 20px;
}

.notification-recent-user {
    color: var(--success);
}

.notification-recent-order{
    color: var(--alpha-lemon)
} 
.notification-text {
    color: var(--alpha-40);
}

.cancel {
    cursor: pointer;
}