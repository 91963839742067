.admin_container ul {
    display: flex;
    justify-content: space-between;
    padding: 1rem 10rem;
    list-style: none;
    background: var(--text-white);
    border-radius: 10px;
}
.admin_container ul > li  {
    color: var(--alpha-40);
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 1.5px;
    padding: .5rem 1.5rem;
    cursor: pointer !important;
}
.tab_color {
    color: var(--alpha-40);
}

.role_container {
    position: relative;
    width: 100%;
    height: 100%;
}
.tabs_active {
    background: var(--alpha-red-35);
    color: var(--alpha-red) !important;
    border-radius: 15px;
    transition: all .3s ease-in;
}

.admin_add {
    width: 15%;
    background: linear-gradient(0deg, rgba(128, 128, 128, 0.25) -17935.87%, rgba(128, 128, 128, 0.12) -15265.57%, rgba(128, 128, 128, 0.1) -12990.87%);
    /* Alpha 40 */
    
    border: 1px solid var(--alpha-40);
    box-sizing: border-box;
    border-radius: 5px;
}
.admin_role_add {
    width: 20%;
    background: linear-gradient(0deg, rgba(128, 128, 128, 0.25) -17935.87%, rgba(128, 128, 128, 0.12) -15265.57%, rgba(128, 128, 128, 0.1) -12990.87%);
    /* Alpha 40 */
    
    border: 1px solid var(--alpha-40);
    box-sizing: border-box;
    border-radius: 5px;
}
.admin_table tr {
    padding: 1rem 2rem !important;
}

.admin_table  .item {
    color: var(--alpha-black-500) !important;
}

.role_header {
    color: var(--alpha-black-500) !important;
}

.align_student_table {
    vertical-align: middle !important;
}

.tborder tr{
    margin-bottom: 1rem !important;
    padding: 2rem 0 !important;
    margin-right: -1rem !important;
}

.tborder tr.align_student_table  {
    border-bottom: 1px solid #EEEEEE !important;
}


.header___name, .header___email, .header___role, .header___id, .header___phone {
    font-family: Circular Std;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 20px;  
    color: var(--alpha-80);
    height: 20px;
}

.header___name {
    margin-left: 6%;
  
}

.header___email {
    margin-left: 12.7%;
}

.header___phone {
    margin-left: 20%;
}

.header___role {
     margin-left: 7.7%;
}

.admin__edit{
    color: var(--alpha-80);
    cursor: pointer;
}

.trash_admin {
    color: var(--alpha-red);
    cursor: pointer;
}


.category_modal {
    padding-bottom: 3rem;
    width: 60%;
    position: absolute;
    top: 0;
    left: 20%;
    z-index: 300;

}

.add_category_form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    justify-content: center;
    margin: 3.5rem auto;
    padding: 1rem;
    position: relative;
}

.add_category_input {
    position: absolute;
    width: 400px;
    height: 43px;
    left: 15%;
    border: 1px solid var(--alpha-40);
    box-sizing: border-box;
    border-radius: 10px;
    outline: none;
}

.add_category_input:focus {
   outline: none;
   padding-left: 1rem;
   font-size: 16px;
   font-weight: 700;
   border-color: var(--alpha-primary);
   background: var(--text-white) !important;
}

.add_category_input:active {
    background: var(--text-white) !important;
}

.role_label {
    position: absolute;
    width: 170px;
    height: 20px;
    background: var(--text-white);
    left: 17%;
    top: -1rem;
    z-index: 100;
    font-family: Circular Std;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    padding: 0px .5rem;
    /* identical to box height, or 143% */
     
    letter-spacing: 1px;
    color: var(--alpha-80);
}

.role_button {
    position: absolute;
    width: 300px;
    height: 48px;
    left: 24%;
    top: 3.5rem;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;

    text-align: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;

    color: var(--text-white);
    border: none;

    background: var(--alpha-black);
    box-shadow: 0px 4px 16px var( --alpha-black-20);
    border-radius: 20px;
}

.create_role_card .create_admin{
    padding: 1rem 1rem 2.5rem 1rem;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr) ) ;
}
.create_admin input {
    width: 100%;
}

.create_admin label {
    font-family: Circular Std;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 20px;
    
    color: var(--alpha-80);
}
.admin_firstname, .admin_lastname, .admin_email, .admin_phonenumber, .admin_assign_role {
    display: flex;
    flex-direction: column;
}

.admin_firstname input, .admin_lastname input, .admin_email input, .admin_phonenumber input, .admin_password input{
    border: none;
    border-bottom: 1px solid var(--alpha-40);
    outline: none;
  
}

.admin_assign_role input{
    border: 1px solid rgba(51, 51, 51, 0.4);
    box-sizing: border-box;
    border-radius: 10px;
    padding-left: 1.7rem;
    width: 282px;
    height: 43px;
    cursor: pointer;
}
.admin_assign_role input:focus {
    outline: none;
    border: 2px solid var(--alpha-primary);
}

.admin_firstname input:focus, .admin_lastname input:focus , .admin_email input:focus , .admin_phonenumber input:focus, .admin_password input:focus {
     outline: none;
     border-bottom: 2px solid var(--alpha-primary);
     transition: all .3s ease-out;
}

.role__input {
    display: flex;
    margin-top: .2rem;
    align-items: center;
    position: relative;
}

.role__input .arrow_down {
    position: absolute;
    right: 15%;
    cursor: pointer;
    transition: all .3s ease-in-out;
}
.arrow_up {
    color: var(--alpha-primary)
}
.role__content {
    width: 264px;
    height: 161px;    
    background: var(--text-white);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    height: auto;
    padding: 1rem;
    margin-left: .5rem;
    text-align: center;
    transition: all .5s ease-in-out;
}
.role__content ul {
    border-bottom: 1px solid rgba(51, 51, 51, 0.4);
    padding-bottom: .3rem;
    width: 100%;
    cursor: pointer;
    list-style: none;
  
 
   
}

.role__content ul li {
    margin-left: -1.5rem;
}


.register_admin {
    width: 370px;
    height: 48px;
    color: var(--text-white);
    text-transform: uppercase;
    background: #1A1A1A;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    border: none;
    margin-top: 2rem;
    margin-left: 17.5rem;
    
}


.close_overlay {
    position: absolute;
    top: -2rem;
    right: 0;
    z-index: 300;
    cursor: pointer;
    font-size: 20px;
    color: var(--text-white);
    background-color: black;
    width: 50px;
    height: 50px;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}