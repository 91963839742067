.card_size {
    height: 130px;
}
.card_size_total {
    height: 285px
}
.card_size_total p {
    font-family: Circular Std;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 0.7px;
    color: var(--alpha-80);
}
.customers_container ul {
    display: flex;
    justify-content: space-between;
    padding: 1rem 10rem;
    list-style: none;
    background: var(--text-white);
    border-radius: 10px;
}

.card__wrapper {
    padding-bottom: 1rem;
}


.card__wrap .table {
    padding: auto 2rem;
}

.customers_container ul > li  {
    color: var(--alpha-40);
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 1.5px;
    padding: .5rem 1.5rem;
    cursor: pointer !important;
}
.tab_color {
    color: var(--alpha-40);
}
.tabs_active {
    background: var(--alpha-red-35);
    color: var(--alpha-red) !important;
    border-radius: 15px;
    transition: all .3s ease-in;
}
.card__header {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    padding: .7rem 0;
    letter-spacing: 1.25px;
    text-align: center !important;
    color: var(--alpha-black-500);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background: var(--text-white);
    border-bottom: 1px solid var(--alpha-40);
}


.header_order_title {
    width: 100%;
}
.divider {
     width: 100%;
     height: 3px;
     border-bottom: 1px solid #808080;
   
}

.header_order_title {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 1.25px;
    color: var(--alpha-black-500);
}

.table-header {
    width: 100%;
    margin-left: .5rem;
    
}

.transactions-item td {
    font-family: Circular Std;
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.02em;
    color: var(--alpha-80);
}

.paid-item {
    color: var(--success) !important;
}
.unpaid-item {
    color: var(--alpha-red) !important;
}
.store-logo {
    width: 30px;
    height: 30px;
    box-shadow: 0px 0px 3px var(--alpha-40);
    border-radius: 50%;
}

.transaction-type-withdraw, .transaction-type-sales {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    text-align: center !important;
    background: rgba(51, 51, 51, 0.1);
    opacity: 0.5;
    border-radius: 50px;
    padding: 5px;
    
}
.sale-icon, .withdraw-icon{
   width: 100%;
   height: 100%;
}

.sale-icon {
    color: var(--success);
}

.withdraw-icon {
    color: var(--alpha-red);
}

.transaction-detail-view {
    font-family: Circular Std;
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1.25px;
    text-decoration-line: underline;
    color: var(--alpha-blue-back);
    cursor: pointer;
}

.transaction_detail_modal {
    height: 100%;
}

.transaction_modal {
    width: 350px;
    height: 500px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 300;
}
.transaction_header {
    position: relative;
}
.modal-closebtn {
    width: 20px;
    height: 20px;
    border-radius: 50px;
    text-align: center !important;
    background: rgba(51, 51, 51, 0.1);
    opacity: 0.5;
    border-radius: 50px;
    padding: 1px;
    position: absolute;
    right: -.5rem;
    top: -1.5rem;
    cursor: pointer;
}

.modal-closebtn .closebtn {
    font-size: 12px;
}

.transaction_detail_heading {
    background-color:  #ECECF0 !important;
    padding: 1rem 1rem;
    border-bottom: 1px solid #ECECF0;
    border-radius: 15px 15px 0px 0px;
    width: 100%;
    height: 180px;
}

.header-transaction-details {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 1rem;
}

.header-transaction-details div:nth-child(n+2){
    margin-top: .7rem;
}

.transaction-text {
    font-family: Circular Std;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.75px;
    color: var(--alpha-blue-back);
}
.transaction-date {
    font-family: Circular Std;
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 1.25px;
    color: var(--alpha-80);
}


.transaction-type-detail {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    text-align: center !important;
    background: rgba(51, 51, 51, 0.1);
    opacity: 0.5;
    border-radius: 50px;
    padding: 5px;
    
}
.sale-icon, .withdraw-icon{
   width: 100%;
   height: 100%;
}
.transaction-card-body {
    padding: 0 !important;
}
.transaction-amount {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
     padding: 1.5rem 0;
     border-bottom: 1px solid rgba(51, 51, 51, 0.1);

}

.transaction-figure {
    font-family: "Inter";
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 16px;
    display: flex;
    align-items: flex-end;
    text-align: center;
    letter-spacing: 1.25px;
    color: var(--alpha-red);
}

.trans-description {
    font-family: "Circular Std";
    font-style: normal;
    font-weight: 450;
    margin-top: 15px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 1.25px;
    color: var(--alpha-80);
}

.transaction-account, .transaction-bank {
    display: flex;
    flex-direction: column;
    justify-content: center;
     padding: 1.5rem 0;
     border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}

.account-placeholder, .bank-placeholder {
    font-family: "Circular Std";
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.25px;
    color: var(--alpha-80);
    margin-left: 2rem;
    margin-bottom: 5px;
}

.account-number, .recipient-bank {
    font-family: "Circular Std";
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 20px;
    letter-spacing: 1.25px;
    color: var(--alpha-80);
    margin-left: 2rem;
}
.merchant__earning_and-chart {
    display: flex;
    flex-direction: unset;
    justify-content: space-between;
    align-items: center;
}
.total__merchants-earning {
    width: 466px;
    height: 237px;
    background: var(--alpha-black-500);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
}

.total__merchants-earning h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 1.25px;
    color: var(--alpha-green);
}
.total__merchants-earning h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    letter-spacing: 1.25px;
    color: var(--text-white)
}
.total__merchants-earning div.date-range {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: auto;
    padding: 0.5rem;
    background: var(--alpha-green);
    border: 1px solid var(--alpha-green);
    border-radius: 5px;
    cursor: pointer;
}
.total__merchants-earning div.date-range .select-range {
        display: flex;
        align-items: center;
        gap: 1rem;
}
.total__merchants-earning div.date-range div {
      width: 200px;
      display:flex;
      align-items: flex-start;
}
.total__merchants-earning div > div:first-child {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1.25px;    
    color: var(--alpha-black-500);
}
.merchant__earning-chart {
    display: flex;
    flex-direction: column;
    width: 466px;
}
.merchant__earning-chart div.choose-date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.merchant__earning-chart div.choose-date div.total-header {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: var(--alpha-80);
}
.merchant__earning-chart div.choose-date div.date-picking {
    position: relative;
    width: 152px;
    right: 0.9rem;
   
}
.merchant__earning-chart div.choose-date div .react-datepicker-wrapper {
    width: 200px !important;
    cursor: pointer !important;
}
.merchant__earning-chart div.choose-date div .react-datepicker-wrapper input{
    background: linear-gradient(0deg, rgba(128, 128, 128, 0.25) -17935.87%, rgba(128, 128, 128, 0.12) -15265.57%, rgba(128, 128, 128, 0.1) -12990.87%);
    border: 1px solid var(--alpha-40);
    border-radius: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1.25px;
    color: var(--alpha-80);
    padding: 0.5rem;
    position: absolute;
    top: -1.5rem;
    
   
}
.merchant__earning-chart div.choose-date div .date-caret {
     position: absolute;
    right: 1rem;
    top: 0;
}