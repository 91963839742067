.payment_modal .delete__item-modal {
    z-index: 99999999;
}
.payment_modal .alpha-overflow {
        position: fixed;
        width: 100vw;
        height: 100%;
        min-height: 100vh;
        min-width: 100vw;
        top: 0;
        left: 0%;
        bottom: 0;
        z-index: 999999;
        background: rgba(0, 0, 0, 0.75);
        cursor: pointer;
}
