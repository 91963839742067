.email-underline {
    text-decoration: underline;
}
.item_proceed button {
    background: linear-gradient(0deg, rgba(128, 128, 128, 0.25) -17935.87%, rgba(128, 128, 128, 0.12) -15265.57%, rgba(128, 128, 128, 0.1) -12990.87%);
    border: 1px solid rgba(51, 51, 51, 0.4);
    border-radius: 5px;
}
.item_phone {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.02em;
}
.item_phone.item_date {
    opacity: 0.65;
    color: var(--alpha-40);
}
.item_ticket {
     width: 100% !important;
}