.login_bg {
    width: 100%;
    height: 100vh;
    background: url("../../assets/image-background.png") rgba(0, 0, 0, 0.6);
    background-position: center;
    background-size: cover;
    background-blend-mode: multiply;
    position: relative;
}


.login__form {
   position: absolute;
   top: 50%;
   right: 10%;
   transform: translate(0, -50%);
   width: 350px;
   
}


.login__container {
    border-radius: 20px;
    height: 490px !important;
}

.reset_error {
    position: absolute;
    left: 20%;
    top: 50%;
    font-size: 20px;

}

.password_link_container {
    border-radius: 20px;
    height: 490px !important;
}

.card__image{
    width: 350px;
    height: auto;
    padding: .75rem 0;
    margin-left: -1px;
    margin-top: -1px;
    letter-spacing: 1.25px;
    text-align: center !important;
    color: var(--alpha-black-500);
    background: linear-gradient(270deg, #131313 5.55%, #333333 92.78%);
    border-radius: 20px 20px 0px 0px;
    border-bottom: 1px solid var(--alpha-40);
}

.form-group h5 {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 32px;
    letter-spacing: -0.743307px;
    text-transform: uppercase;
    color: var(--alpha-black);
}

.form-input {
    position: relative;
}
.form-input .form_control {
    width: 100%;
    position: absolute;
    padding-left: 1.5rem;
    padding-bottom: 0.4rem;
    font-family: Circular Std;
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #B3B3B3;
    border: none;
    border-bottom: 1px solid rgba(51, 51, 51, 0.8);
    background-color: transparent;
    border-radius: 0 !important;
}

.form-input .form_control:focus {
    outline: none !important;
    border-width: 2px;
    border-color: var(--alpha-primary);
    transition: all .3s ease-out;
} 
.user_icon {
    position: absolute;
    z-index: 3;
}

.login_welcome {
    font-family: Inter;
    font-style: normal;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.25px;
    color: var(--alpha-black);
    padding: 1rem 0 0 0;
}

.otp_welcome {
    font-family: Inter;
    font-style: normal;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.25px;
    color: var(--alpha-black);
    padding: 1rem 0 0 0;
}

.otp_reset_pass {
    font-family: Inter;
    font-style: normal;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.743307px;
    text-transform: uppercase;
    color: var(--alpha-black);
}

.form-group-2 {
    margin-top: 2.5rem;
}

.form__group {
    margin-top: 3rem;
}

.btn__reset {
    margin-top: 2rem;
}

.form-group-3 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 3.5rem;
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: var(--alpha-80);
  
}

.remeber_me {
    display: flex;
    align-items: center;
}



.check_option {
    border: 2px solid rgba(51, 51, 51, 0.8);
}
.rememberMe {
    margin-left: 5px;
}

.forgot_passord{
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    
    color: var(--alpha-red);
}

.forgot_passord:hover {
    color: var(--alpha-red); 
}

.button_submit {
    width: 100%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-white);
    margin-top: 1.2rem;
    padding: .75rem;
    height: 2.5rem;
    border: none;
    outline: none;
    background: var(--alpha-black);
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
}

.button_reset {
    width: 100%;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: var(--text-white);
    margin-top: 3rem;
    padding: .75rem;
    height: 2.5rem;
    border: none;
    outline: none;
    background: var(--alpha-black);
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
}

.reset_email {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    margin-top: 2rem;
    text-align: center;
    line-height: 32px;
    letter-spacing: -0.743307px;
    text-transform: uppercase;
    color: var(--alpha-black);
}

.reset_text_hint {
    font-family: Circular Std;
    margin-top: -10px;
    padding: .5rem 0;
    font-style: normal;
    font-weight: 450;
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: var(--alpha-red);
    opacity: 0.75;
}

.items_display {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.success_link_sent {
    font-family: Circular Std;
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: var(--success);
    opacity: 0.75;
}

.checked_image {
    color: var(--success);
    font-size: 100px;
}

.link_hint {
    width: 200px;
    font-family: Circular Std;
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.02em;
    color: var(--alpha-40);
    opacity: 0.75;
}