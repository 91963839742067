.validation-container .validation-tabs {
     display: flex;
     justify-content: space-between;
     align-items: center;
}

.validation-container .validation-tabs a {
    width: 24%;
    height: 80px;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    text-decoration: none;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap: 0.75rem;
    padding: 0.5rem
}
.validation-container .validation-tabs.active-tab a:first-child {
    background: #53ACFF;
    transition: all 0.3s ease linear;
}
.validation-container .validation-tabs.active-tab-approved a:nth-child(2){
    background: #009A59;
    transition: all 0.3s ease linear;
}
.validation-container .validation-tabs.active-tab-rejected a:nth-child(3){
    background: #ED3237;
    transition: all 0.3s ease linear;
}
.validation-container .validation-tabs.active-tab-all a:nth-child(4){
    background: var(--alpha-80);

    transition: all 0.3s ease linear;
}
.validation-container .validation-tabs a div.icon-one {
    padding: 0.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #53ACFF;
    color: #FFFFFF;
}
.validation-container .validation-tabs a div.icon-two {
    padding: 0.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #009A59;
    color: #FFFFFF;
}
.validation-container .validation-tabs a div.icon-three {
    padding: 0.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--alpha-red);
    color: #FFFFFF;
}
.validation-container .validation-tabs a div.icon-four {
    padding: 0.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--alpha-80);
    color: #FFFFFF;
}

.validation-container .validation-tabs a div:nth-child(2){
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    display: flex;
    align-items: flex-end;
    letter-spacing: 1.25px;
    /* color: #333333; */
}
.validation-container .validation-tabs a div:nth-child(2) > div.active-one, .validation-container .validation-tabs a div:nth-child(2) > div.active-two, .validation-container .validation-tabs a div:nth-child(2) > div.active-three, .validation-container .validation-tabs a div:nth-child(2) > div.active-four {
    font-size: 14px;
    color: #333333;

}
.validation-container .validation-tabs.active-tab a div:nth-child(2) > div.active-one, .validation-container .validation-tabs.active-tab-approved a div:nth-child(2) > div.active-two, .validation-container .validation-tabs.active-tab-rejected a div:nth-child(2) > div.active-three, .validation-container .validation-tabs.active-tab-all a div:nth-child(2) > div.active-four {
    color: #FFFFFF !important;
}
.validation-container .validation-tabs a div:nth-child(2) div.badge {
    color: rgba(51, 51, 51, 0.4);
    margin-bottom: -0.20rem;
    font-size: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
}
.validation-container .validation-tabs.active-tab a div:nth-child(2) div.badge.one, .validation-container .validation-tabs.active-tab-approved a div:nth-child(2) div.badge.two, .validation-container .validation-tabs.active-tab-rejected a div:nth-child(2) div.badge.three, .validation-container .validation-tabs.active-tab-all a div:nth-child(2) div.badge.four{
    color: #FFFFFF !important;
}
.validation-container .validation-tabs.active-tab  div.icon-one {
    background: #FFFFFF;
    color: #53ACFF;
}
.validation-container .validation-tabs.active-tab-approved  div.icon-two {
    background: #FFFFFF;
    color: #009A59;
}
.validation-container .validation-tabs.active-tab-rejected  div.icon-three {
    background: #FFFFFF;
    color: var(--alpha-red);
}
.validation-container .validation-tabs.active-tab-all  div.icon-four {
    background: #FFFFFF;
    color: var(--alpha-80);
}
.header-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #333333;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.header-title span {
    font-weight: 400;
    font-size: 12px;
    color: var(--alpha-40) !important;
}
.validate-product__sec th {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 20px;
    color: var(--alpha-80) !important;
}

.validate-product__body td{
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.02em;
    color: #333333;
}
.validate-product__body td a {
    padding: 0.25rem 0.5rem;
    background: linear-gradient(0deg, rgba(128, 128, 128, 0.25) -17935.87%, rgba(128, 128, 128, 0.12) -15265.57%, rgba(128, 128, 128, 0.1) -12990.87%);
    border: 1px solid rgba(51, 51, 51, 0.4);
    border-radius: 5px;
    color: var(--alpha-80);
    width: 5rem;
    display: flex;
    align-items: center;
}
.validate-product__body .pending{
    color: var(--alpha-lemon);
}
.validate-product__body .approved{
    color: var(--success);
}
.validate-product__body .disapproved{
    color: var(--alpha-red);
}
.validate-sect {
    position: relative;
    width: 100%;
    height: 100%;
}
.validate-sect .gallery-media {
     width: 100%;
     height: 100%;
     position: fixed;
     background: linear-gradient(0deg, rgba(0, 0, 0, 0.69), rgba(0, 0, 0, 0.69));
     z-index: 999999999;
     top: 0;
     left: 0;
     bottom: 0;
     right: 0;
}
.validate-sect .gallery-media .gallery__media-container {
    position: relative;
    height: 70vh;
    width: 70vw;
    top: 50%;
    left: 55%;
    transform: translate(-50%,-50%);
    display: flex !important;
    align-items: center;
    justify-content: center;
    z-index: 9999999999;

    
}

.validate-sect .gallery-media .slider .full-img, .validate-sect .gallery-media .slider .full-video {
     width: 700px;
     height: 500px;
     background: var(--alpha-40) !important;
     border: 1px solid var(--alpha-10);
     border-radius: 10px;
     z-index: 99999999999;
     cursor: pointer;
}
.validate-sect .gallery-media .slider .full-img img, .validate-sect .gallery-media .slider .full-video video {
     width: 100%;
     height: 100%;
     border-radius: 10px;
}
.validate-sect .gallery-media .slider .full-video {
    background: var(--alpha-black) !important;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.validate-sect .gallery-media .slider .full-video .play{
    position: absolute;
    font-size: 40px;
    color: var(--alpha-blue-back);
    cursor: pointer;
    transition: opacity 200ms linear;
}
.validate-sect .gallery-media .slider .full-video .control-shown {
    opacity: 1;
}
.validate-sect .gallery-media .slider .full-video .control-hidden {
    opacity: 0;
}
.validate-sect .gallery-media .slider .next {
     position: absolute;
     right: -3rem;
     user-select: none;
}
.validate-sect .gallery-media .slider .prev {
    position: absolute;
    left: -3rem;
    user-select: none;
}
.validate-sect .gallery-media .slider .slide{
    opacity: 0;
    transition: 1s ease;
}
.validate-sect .gallery-media .slider .slide.active {
    opacity: 1;
    transition: 1s;
    transform: scale(1.08);
}
.validate-sect .gallery-media .gallery-close {
    position: fixed;
    right: 2%;
    top: 10%;
    color: var(--text-white);
    z-index: 9999999999;
    font-size: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
}
.validate-sect .gallery-media .gallery-close span {
    font-size: 16px !important;
}
.validate-sect .gallery-media .gallery-close:hover {
    color: var(--alpha-red);
}
/* .validate-sect .gallery-media .gallery__media-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
} */
.validate-sect .gallery-media .gallery__media-container .prev,.validate-sect .gallery-media .gallery__media-container .next {
    font-size: 40px;
    color: var(--text-white);
    font-weight: bold;
    cursor: pointer;
}
.card-validate {
     background: var(--text-white);
     box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.05);
     border-radius: 15px !important;
     min-height: 622px;
}
.card-validate .card-header {
    background: var(--text-white); 
    border-radius: 15px 15px 0px 0px !important;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
}
.card-validate .card-body {
    border-radius: 0px 0px 15px 15px !important;
    padding-bottom: 2.5rem;
}
.card-validate .card-header {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 1.25px;
    color: #333333;
}
.card-validate .card-header div:first-child {
    cursor: pointer;
    font-size: 16px !important;
}
.card-validate .card-header div:last-child span {
    font-weight: 400;
    font-size: 12px;
    color: var(--alpha-40) !important;
}
.card-media p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.25px;
    color: #333333;
}

.thumbnail-images-styles {
      display: flex;
      width: 100%;
      gap: 0.5rem;
      align-items: center;
}
.thumbnail-images-styles div.img-sect {
    display: flex;
    width: 19%;
}
.thumbnail-images-styles .img-thumbnail {
    width: 100%;
    height: 119px;
    border: none !important;
    position: relative;
    display: inline-block;
    background: var(--alpha-10);
    border-radius: 10px;
}
.thumbnail-images-styles img {
     width: 100%;
     height: 100%;
     border-radius: 10px;

}
.thumbnail-images-styles .img-thumbnail > .overlay {
    position: absolute;
    transition: all .3s ease;
    opacity: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.69), rgba(0, 0, 0, 0.69));
    border-radius: 10px;
}
.thumbnail-images-styles .img-thumbnail:hover .overlay {
    opacity: 1;
}
.thumbnail-images-styles .img-thumbnail > .overlay .overlay-text {
    color: var(--text-white);
    font-size: "Circular Std";
    font-size: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.thumbnail-images-styles .img-thumbnail > .overlay-left {
   height: 100%;
   width: 0;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
}
.thumbnail-images-styles .img-thumbnail:hover .overlay-left {
    width: 100%;
}
.thumbnail-images-styles .video-thumbnail {
     width: 100%;
     height: 119px;
     position: relative;
     border-radius: 10px;
     display: inline-block;
     /* border: 1px solid var(--alpha-40); */
   
}
.thumbnail-images-styles .video-thumbnail video {
     width: 100%;
     height: 100%;
     object-fit: contain;
}
.thumbnail-images-styles .video-thumbnail div {
     position: absolute;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     display: flex;
     justify-content: center;
     align-items: center;
     background: linear-gradient(0deg, rgba(0, 0, 0, 0.69), rgba(0, 0, 0, 0.69));
     border-radius: 10px;
     color: var(--text-white);
     font-size: 30px;
     z-index: 9999999;
     border: none;
}

.product-description p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.25px;
    color: var(--alpha-black-500);
}
.product-description .detail-one {
       display: flex;
       justify-content: space-between;
       align-items: center;
       width: 100%;
       position: relative;
       margin-top: -0.5rem;
}
.product-description .detail-one label {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    /* line-height: 20px; */
    color: var(--alpha-black-500);
}
.product-description .detail-one input {
    height: 50px;
    border: 1px solid var(--alpha-40);
    border-radius: 10px;
    padding: 0 1rem;
    outline: none;
}
.product-description .detail-one .category-detail, .product-description .detail-one .type-detail{
    width: 38%;
}
.product-description .detail-one .category-detail input, .product-description .detail-one .type-detail input {
    width: 100%;
}
.product-description .detail-one .fit-detail {
    width: 22%;
}
.product-description .detail-one .fit-detail input {
      width: 100%;
}
.product-description .detail-two{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    /* margin-top: -3.5rem; */
}
.product-description .detail-two label {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    /* line-height: 20px; */
    color: var(--alpha-black-500);
}
.product-description .detail-two input {
    height: 50px;
    border: 1px solid var(--alpha-40);
    border-radius: 10px;
    padding: 0 1rem;
    outline: none;
}
.product-description .detail-two textarea {
     height: 142px;
     border: 1px solid var(--alpha-40);
     border-radius: 10px;
     padding: 0.5rem 1rem;
     outline: none;
}
.product-description .detail-two .category-detail{
    width: 38%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
}
.product-description .detail-two .category-detail input {
    width: 100%;
}

.product-description .detail-two .description-detail {
      width: 61%;
      margin-top: 0.25rem;
}
.product-description .detail-two .description-detail textarea {
      width: 100%;
}
.product-description .detail-three {
     display: flex;
     align-items: center;
     width: 100%;
     overflow-x: scroll;
     -ms-overflow-style: none;  /* Internet Explorer 10+ */
     scrollbar-width: none;  /* Firefox */
    
}
.product-description .detail-three::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }
.product-description .detail-three .size-qty {
       display: flex !important;
       gap: 1rem;
}
/* .product-description .detail-three .size-qty div.color-qty-size {
      display: flex;
} */
.product-description .detail-three .size-qty .color-qty-size {
      display: flex;
      align-items: center;
      gap: 1rem;
      width: 100% !important;
}
.product-description .detail-three .size-qty .color-qty-size div.first-child {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 20px;
    color: var(--alpha-40);
    gap: 0.25rem;
    min-width: 75px;
    max-width: 100px;
    height: 40px;
    border: 1px solid var(--alpha-40);
    border-radius: 10px;
}
.product-description .detail-three .size-qty .color-qty-size .first-child > div:nth-child(1){
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EBEBEB;
    border-radius: 5px;
    min-width: 25px;
    padding: 2px;
    height: 25px;
    
}
.product-description .detail-three .all-color {
     display: flex;
}
.product-description .detail-three .all-color div {
    width: 24px;
    height: 24px;
    border: 1px solid var(--text-white);
    border-radius: 50%;
}
.product-description .detail-three .all-color div:nth-child(n + 2){
      margin-left: -1rem;
}
.card-validate .product-checklists {
    height: 100%;

}
.card-validate .product-checklists label {
      display: flex;
      justify-content: space-between;
      align-items: center;
}
.card-validate .product-checklists input[type="checkbox"]{
     cursor: pointer;
}
.card-validate .product-checklists div.accept-condition {
     display: flex;
     align-items: flex-start;
     height: 100%;
     gap: 0.75rem;
}

.card-validate .product-checklists div.accept-condition div:last-child {
    font-family: 'Circular Std';
    font-style: italic;
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
    text-align: justify;    
    color: rgba(51, 51, 51, 0.4);
}
.card-validate .product-checklists label:nth-child(n+2){
    margin-top: 0.5rem;
}
.card-validate .product-checklists.disapproved-list {
    display: flex;
    flex-direction: column;
    gap: 1rem !important;
}
.card-validate .product-checklists label .ant-checkbox{
      margin-top: -0.75rem;
}
.card-validate .product-checklists label span {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 20px;
}
.card-validate .product-checklists .accept-terms {
    font-family: 'Circular Std';
    font-style: italic;
    margin: 1rem auto;
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
    text-align: justify;
    color: var(--alpha-lemon)
}
.ant-checkbox .ant-checkbox-inner{
    border: 1px solid var(--alpha-black-500) !important;
}

.ant-checkbox-checked .ant-checkbox-inner{
     background: var(--alpha-black-500) !important;
     border: 1px solid var(--alpha-black-500) !important;
}
.address-location {
     width: 190px !important;
     max-width: 200px !important;
    white-space: nowrap;
     overflow-x:auto;
     scrollbar-width: thin !important;
    
}
.card-validate .product-checklists label span:nth-child(2){
     color: var(--alpha-40);
     cursor: pointer;
}

td.address-location::-webkit-scrollbar {
    height: 7px !important;
}
   
.address-location::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
   
.address-location::-webkit-scrollbar-thumb {
    background-color: darkgrey;
}

.disapproval-message {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.25px;    
    color: #333333;
}

.disapproval-message textarea{
    width: 100%;
    border: none;
    outline: none;
    border-bottom: 2px solid var(--alpha-40);
    height: 2.5rem;
    padding: 0.5rem 0;
}
.validate-btn {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: var(--text-white);
}
.validate-btn button {
    background: #1A1A1A;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    height: 48px;
    width: 230px;
    border: none;
}
input[type="checkbox"].disabled-check {
    accent-color: var(--alpha-black-500);
  }
input[type="checkbox"].danger-check {
    accent-color: transparent !important;
    border-radius: 10px !important;
    box-shadow: 0px 0px 0px 1px red inset;

}
  .text-danger-alpha {
       color: var(--alpha-red);
  } 
  .text-success-alpha {
       color: var(--alpha-success);
  }
  .disapproved-badge {
     display: flex;
     width: 100%;
     align-items: center;
     justify-content: center;
     gap: 0.5rem;
     margin-top: 3rem;
}
.disapproved-badge .icon {
    font-size: 20px;
}
.disapproved-badge span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;
}
